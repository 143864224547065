import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'
import { useSnackbar } from 'notistack'
import { SnackbarTranslations, Translations } from '../../config/i18n/i18n'

const useCookie = (name: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.SNACKBAR])
  const cookies = new Cookies()

  const [item, setItemValue] = useState<string | undefined>(() => {
    const cookie = cookies.get(name)
    return cookie ?? undefined
  })

  const setItem = (value: string) => {
    try {
      cookies.set(name, value)
      setItemValue(value)
    } catch (error) {
      enqueueSnackbar(t(SnackbarTranslations.CANNOT_STORE_COOKIE), {
        variant: 'error',
      })
    }
  }

  const removeItem = () => {
    try {
      cookies.remove(name)
      setItemValue(undefined)
    } catch (error) {
      enqueueSnackbar(t(SnackbarTranslations.CANNOT_REMOVE_COOKIE), {
        variant: 'error',
      })
    }
  }

  return { item, setItem, removeItem }
}

export default useCookie
