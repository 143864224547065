import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../config'

import { CommonButton } from '../../../../commons/components/CommonButton/CommonButton'
import { ButtonValues } from '../../../../commons/types'
import { ModalGnomeCard } from './components/ModalGnomeCard/ModalGnomeCard'

import {
  DescriptionTextModal,
  ModalOpenChestContainer,
  ModalOpenChestText,
  TitleTextModal,
  WrapperButtonExample,
} from './ModalOpenChest.style'

/* interface ModalOpenChestProps {
  gnome: Gnome
  title?: string
  desc?: string
  mainButton: () => void
  secondaryButton: () => void
} */

export function ModalOpenChest() {
  const { t } = useTranslation([Translations.COMMON])
  return (
    <ModalOpenChestContainer>
      <ModalOpenChestText>
        <ModalGnomeCard />

        <TitleTextModal>
          {t(CommonTranslations.MODAL_BUY_CHEST_MODAL_TITLE)}
        </TitleTextModal>

        <DescriptionTextModal>
          {t(CommonTranslations.MODAL_BUY_CHEST_MODAL_SUBTITLE)}
        </DescriptionTextModal>
      </ModalOpenChestText>

      <WrapperButtonExample>
        <CommonButton
          variant={ButtonValues.White}
          text={t(CommonTranslations.MODAL_BUY_CHEST_MODAL_MAIN_BUTTON)}
          clickHandler={() => ''}
          full
        />

        <CommonButton
          text={t(CommonTranslations.MODAL_BUY_CHEST_MODAL_SECONDARY_BUTTON)}
          clickHandler={() => ''}
          full
        />
      </WrapperButtonExample>
    </ModalOpenChestContainer>
  )
}
