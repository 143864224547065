import {
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material'
import { commons } from '../../../images'

export const RoadmapCardContainer = styled(Grid)({
  padding: 50,
  borderRadius: '20px',
  background: `url(${commons.board})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: 281,
  height: 378,
})

export const RoadmapCardTitle = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px',
})

export const RoadmapTypography = styled(Typography)({
  fontSize: '30.8721px',
})

export const RoadmapDivider = styled(Divider)({
  marginTop: '20px',
  marginBottom: '30px',
  color: 'black',
  borderColor: 'black',
})

export const RoadmapList = styled(List)({
  listStyleType: 'disc',
  paddingLeft: '16px',
})

export const RoadmapListItem = styled(ListItem)({
  display: 'list-item',
  fontSize: '16.34px',
  padding: '0',
})
