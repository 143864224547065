import React, { useEffect } from 'react'
import { SnackbarProvider } from 'notistack'
import ga from 'react-ga4'
import { ThemeProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from './routes/AppRoutes'
import CustomTheme from './commons/theme/CustomTheme'
import { ANALYTICS_MEASUREMENT_ID } from './config'
import { AppProvider } from './commons/contexts/AppContext'
import { AxiosErrorHandler } from './services/backend/AxiosErrorHandler'

function App() {
  useEffect(() => {
    if (ANALYTICS_MEASUREMENT_ID) ga.initialize(ANALYTICS_MEASUREMENT_ID)
  }, [])

  return (
    <BrowserRouter>
      <SnackbarProvider style={{ fontSize: '2rem' }}>
        <ThemeProvider theme={CustomTheme}>
          <AppProvider>
            <AxiosErrorHandler>
              <AppRoutes />
            </AxiosErrorHandler>
          </AppProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </BrowserRouter>
  )
}

export default App
