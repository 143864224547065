import { styled } from '@mui/material'

export const RankingImage = styled('img')({
  maxWidth: '183.76px',
  maxHeight: '205.04px',
  height: 'auto',
  width: '25%',
  userSelect: 'none',
  pointerEvents: 'none',
})
