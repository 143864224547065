import { Grid, styled } from '@mui/material'

export const GnomeContainer = styled(Grid)({
  display: 'flex',
  gap: '1rem',
  flexWrap: 'wrap',
  padding: '1rem',
  margin: '1rem',
  flex: 1,
  justifyContent: 'center',
})
