import React from 'react'
import { ResponsiveCarousel } from '../ResponsiveCarousel/ResponsiveCarousel'
import { TeamWorkerCard } from '../TeamWorkerCard'
import { team } from '../../constants'

export function TeamCarousel() {
  return (
    <ResponsiveCarousel>
      {team.map(({ name, position, image }, i) => (
        <TeamWorkerCard
          key={i}
          name={name}
          position={`${position} ${i}`}
          img={image}
        />
      ))}
    </ResponsiveCarousel>
  )
}
