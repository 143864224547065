import { Grid, styled } from '@mui/material'

export const MenuLayoutGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  gridTemplateRows: '0 auto',
  height: '100%',
  width: 'inherit',
  boxSizing: 'border-box',
  alignItems: 'center',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}))
