import { Button, Grid, Skeleton, styled, Typography } from '@mui/material'
import { GameTypography, GridContainer } from '../../commons/Custom.style'
import { background } from '../../images'

export const MainSectionContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  display: 'grid',
  gridTemplateRows: '50% 50%',
  background: `url(${background.natureWaves}), ${theme.palette.background.primary}`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  paddingTop: 120,
}))

export const MainSectionImageContainer = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const MainSectionTextContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateRows: '40% auto',
  [theme.breakpoints.down('md')]: {
    gridTemplateRows: 'repeat(3, auto)',
  },
}))

export const CustomContainer = styled(GridContainer)(() => ({
  display: 'flex',
}))

export const LandingPageContainer = styled(Grid)(({ theme }) => ({
  height: 'auto',
  width: '100%',
  background:
    'linear-gradient(229.1deg, rgba(255, 226, 136, 0.5) 0%, rgba(255, 226, 136, 0) 72.71%), #ADE5FF',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}))

export const TypographyTitle = styled(GameTypography)(({ theme }) => ({
  color: '#ffffff',
  letterSpacing: '0',
  fontWeight: '400',
  textTransform: 'none',
  fontSize: '40px',
  maxWidth: '920px',
  lineHeight: '38.76px',
  textAlign: 'center',
  fontFamily: 'Onacona',
  [theme.breakpoints.down('md')]: {
    maxWidth: '68rem',
    fontSize: '3rem',
  },
}))

export const TypographySubTitle = styled(Typography)(({ theme }) => ({
  maxWidth: '850px',
  textAlign: 'center',
  fontSize: '5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
  },
}))

export const TypographySecondary = styled(Typography)(() => ({
  maxWidth: '800px',
  textAlign: 'center',
  fontSize: '1.5rem',
}))

export const TypographyDescription = styled(Typography)({
  maxWidth: '819px',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '2.64rem',
})

export const CircularSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: '50%',
  width: '350px',
  height: '550px',
  [theme.breakpoints.down('md')]: {
    width: '300px',
    height: '500px',
  },
}))

export const SolidButton = styled(Button)(() => ({
  backgroundColor: '#000000',
  width: '130px',
  height: '40px',
  color: '#ffffff',
  fontSize: '1.3rem',
}))

export const GnomesCompetitionContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  background: `url(${background.orangeWaves})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  paddingBottom: '50px',
  [theme.breakpoints.down('md')]: {
    paddingBottom: '0',
    height: 'auto',
  },
}))
