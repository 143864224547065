import { useCallback } from 'react'
import ga from 'react-ga4'
import { ANALYTICS_MEASUREMENT_ID } from '../../config'

export function useAnalitycsEvents() {
  const trackWebInit = useCallback(() => {
    if (ANALYTICS_MEASUREMENT_ID)
      ga.event('CLICK', {
        category: 'LINK',
        label: 'WEB_INIT',
      })
  }, [])

  const trackGameplay = useCallback(() => {
    if (ANALYTICS_MEASUREMENT_ID)
      ga.event('CLICK', {
        category: 'LINK',
        label: 'WEB_GAMEPLAY',
      })
  }, [])

  const trackGnomes = useCallback(() => {
    if (ANALYTICS_MEASUREMENT_ID)
      ga.event('CLICK', {
        category: 'LINK',
        label: 'WEB_GNOMES',
      })
  }, [])

  const trackLitepaper = useCallback(() => {
    if (ANALYTICS_MEASUREMENT_ID)
      ga.event('CLICK', {
        category: 'LINK',
        label: 'WEB_LITEPAPER',
      })
  }, [])

  const trackRoadmap = useCallback(() => {
    if (ANALYTICS_MEASUREMENT_ID)
      ga.event('CLICK', {
        category: 'LINK',
        label: 'WEB_ROADMAP',
      })
  }, [])

  return {
    trackWebInit,
    trackGameplay,
    trackGnomes,
    trackLitepaper,
    trackRoadmap,
  }
}
