import { Button, Grid, styled } from '@mui/material'
import { background } from '../../../images'
import { LoadingTypography } from '../../../commons/Custom.style'

export const LoginLayout = styled(Grid)(() => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#300202',
  background: `url(${background.nature})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}))

export const ContainerForm = styled(Grid)(() => ({
  height: '675px',
  width: '400px',
  borderRadius: '11px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '50px',
  flexDirection: 'column',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  background: `url(${background.tableContainer})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  backgroundColor: '#4F1200',
}))

export const LoginButton = styled(Button)(({ theme }) => ({
  width: '300px',
  height: '50px',
  backgroundColor: '#ffffff',
  marginTop: 'auto',
  background: `url(${background.tableHorizontal})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  border: 'none',
  borderRadius: 'unset',
  boxShadow: '5px 7px 10px black',
  transition: theme.transitions.create(['width', 'height']),
  '&:hover': {
    width: '310px',
    height: '55px',
  },
}))

export const LoginButtonTypography = styled(LoadingTypography)(({ theme }) => ({
  fontSize: '20px',
  color: theme.palette.font?.main,
  fontWeight: '800',
  textShadow: '2px 3px 5px #000000',
  letterSpacing: '1.5px',
}))

export const LogoImage = styled('img')({
  width: '300px',
  userSelect: 'none',
})
