import React, { useState, useEffect, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Pagination } from '@mui/material'
import { fetchItems } from '../../services/backend'
import {
  CommonTranslations,
  SnackbarTranslations,
  Translations,
} from '../../config/i18n/i18n'
import { Item } from './models/Item'

import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
import { InventoryItem } from '../../commons/components/InventoryItem'
import { CommonTitle } from '../../commons/components/CommonTitle/CommonTitle'
/* import { SelectSort } from '../../commons/components/SelectSort/SelectSort' */

import {
  ContentContainer,
  InventoryItemContainer,
  ItemsContainer,
  PaginationWrapper,
} from './Inventory.style'

export function Inventory() {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])
  const [items, setItems] = useState<Item[]>([])
  const [pages, setPages] = useState<number>(1)

  const getItems = useCallback(
    async (page: number) => {
      try {
        const itemsPerPage = 3
        const data = await fetchItems({
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage,
        })
        setItems(data.results)
        const totalPages = Math.ceil(data.count / itemsPerPage)
        setPages(totalPages)
      } catch (err) {
        enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_ITEMS), {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar, t],
  )

  useEffect(() => {
    getItems(1)
  }, [getItems])

  const handleChange = (_: unknown, page: number) => {
    getItems(page)
  }

  return (
    <ItemsContainer
      id="inventory-section"
      data-testid="inventory:page-container"
    >
      <DashboardAppBar />

      <ContentContainer data-testid="inventory:content-container">
        <CommonTitle
          title={`${t(CommonTranslations.INVENTORY)}`}
          data-testid="inventory:page-title"
        />

        {/* <SelectSort defaultOptions /> */}

        <InventoryItemContainer data-testid="inventory:items-container">
          {items.map(item => (
            <InventoryItem key={item.id} item={item} />
          ))}
        </InventoryItemContainer>

        <PaginationWrapper>
          <Pagination
            count={pages}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </PaginationWrapper>
      </ContentContainer>
    </ItemsContainer>
  )
}
