import React from 'react'
import { RoadmapCard } from '../RoadmapCard'
import { ResponsiveCarousel } from '../ResponsiveCarousel'

export function RoadmapCarousel() {
  return (
    <ResponsiveCarousel>
      {Array.from({ length: 4 }, (_, i) => (
        <RoadmapCard
          q={i.toString()}
          year="2022"
          list={['Lorem', 'Consectetur adipiscing', 'Phasellus', 'Scelerisque']}
          key={i}
        />
      ))}
    </ResponsiveCarousel>
  )
}
