import React from 'react'

import { GnomeType } from '../../types'
import { GetGnomeType } from '../../../pages/Chest/components/ModalOpenChest/components/Utils/getGnomeType'

import { GnomeTagContainer } from './GnomeTag.style'

interface GnomeTagProps {
  gnomeType: GnomeType
}

export function GnomeTag({ gnomeType }: GnomeTagProps) {
  return (
    <GnomeTagContainer gnomeType={gnomeType}>
      {GetGnomeType({ gnomeType })}
    </GnomeTagContainer>
  )
}
