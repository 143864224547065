import React from 'react'
import {
  CircularSkeleton,
  MainSectionImageContainer,
  MainSectionContainer,
  MainSectionTextContainer,
  TypographyTitle,
  TypographyDescription,
} from './Main.style'
import { GridContainer } from '../../../../commons/Custom.style'
import { GameButton } from '../../../../commons/components/GameButton'

export function MainSection() {
  return (
    <MainSectionContainer
      id="main-section"
      data-testid="main:section-container"
    >
      <MainSectionImageContainer data-testid="main:image-container">
        <CircularSkeleton data-testid="main:skeleton-image-loading" />
      </MainSectionImageContainer>

      <MainSectionTextContainer data-testid="main:text-container">
        <GridContainer data-testid="main:grid-container">
          <TypographyTitle>
            Arma tu equipo de gnomos y sal al campo de batalla para demostrar
            quien es el líder del jardín
          </TypographyTitle>
          <TypographyDescription>
            Un juego blockchain táctico de artillería por turnos en el que
            puedes adquirir tus gnomos NFTs y competir contra otros equipos.
            Proximamente disponible...
          </TypographyDescription>
        </GridContainer>
        <GridContainer data-testid="main:grid-container">
          <GameButton>Litepaper</GameButton>
        </GridContainer>
      </MainSectionTextContainer>
    </MainSectionContainer>
  )
}
