import { css, styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const BarNavBrand = styled('img')(
  () => css`
    width: auto;
    height: 90px;
    flex: 0 0 auto;
  `,
)

export const BarNavLink = styled(NavLink)(
  ({ theme }) => css`
    width: auto;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    color: ${theme.palette.font.neutral40};
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
    border-radius: 8px;

    &:hover,
    &.active {
      color: ${theme.palette.font.warning};
      background-color: ${theme.palette.font.warningLight};
    }
  `,
)

export const BarNavLinkWrapper = styled('div')(
  () => css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0 auto;
  `,
)

export const BarNavBorder = styled('div')(
  ({ theme }) => css`
    width: 100vw;
    height: 1px;
    background-color: ${theme.palette.font.neutral10};
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: 0;
  `,
)

export const DashboardAppBarNav = styled('nav')(
  () => css`
    width: 100%;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem auto;
    padding-bottom: 1.5rem;
    gap: 1rem;
    position: relative;
  `,
)
