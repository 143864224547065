import { css, styled } from '@mui/material'

export const ErrorFeedback = styled('div')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.error};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;
    text-align: left;
    box-sizing: border-box;
  `,
)

export const InfoTokenSymbol = styled('img')(
  () => css`
    width: 2rem;
    height: 2rem;
    flex: 0 0 auto;
  `,
)

export const TagTextGray = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral40};
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  `,
)

export const InfoTagContent = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  `,
)

export const InfoTagLabel = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.2px;
    display: flex;
    align-items: center;
    text-align: left;
    white-space: nowrap;
  `,
)

export const InfoTag = styled('div')(
  ({ theme }) => css`
    width: 100%;
    min-height: 32px;
    background-color: ${theme.palette.font.neutral05};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-radius: 12px;
    padding: 1rem;
    box-sizing: border-box;
  `,
)

export const ChestInfoWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    align-items: center;
  `,
)

export const ChestImg = styled('img')(
  () => css`
    width: 100%;
    max-width: 200px;
    height: auto;
    flex: 0 0 auto;
  `,
)

export const ChestImgWrapper = styled('div')(
  ({ theme }) => css`
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
    background-color: ${theme.palette.font.warningLight};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4rem 1.5rem;
  `,
)

export const ChestCardTitle = styled('div')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.neutral60};
    font-size: 26px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.26px;
    text-transform: capitalize;
  `,
)

export const ChestCardContainer = styled('div')(
  ({ theme }) => css`
    width: 100%;
    max-width: 370px;
    height: auto;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    flex-flow: column;
    gap: 1rem;
    background-color: ${theme.palette.font.main};
    box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.1);
    border: 1px solid ${theme.palette.font.neutral10};
    border-radius: 1rem;
    box-sizing: border-box;
  `,
)
