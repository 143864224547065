import { styled } from '@mui/material'
import { GameTypography } from '../../Custom.style'
import { background } from '../../../images'

export const ContainerSizeMenu = styled('div')({
  display: 'flex',
  width: '40rem',
  height: 100,
  backgroundSize: '100% !important',
  backgroundRepeat: 'no-repeat !important',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ContainerSizeMenuLeft = styled(ContainerSizeMenu)({
  background: `url(${background.woodLeft})`,
})

export const ContainerSizeMenuRight = styled(ContainerSizeMenu)({
  background: `url(${background.woodRigth})`,
})

export const AppBarTypography = styled(GameTypography)(({ theme }) => ({
  color: theme.palette.font?.main,
  fontSize: '2.75rem',
  lineHeight: '33px',
  textTransform: 'capitalize',
}))
