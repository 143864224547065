import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserProvider } from 'ethers'
import { useSnackbar } from 'notistack'
import useCookie from '../hooks/useCookie'
import { nonce, sign } from '../../services/lambda'
import { SnackbarTranslations, Translations } from '../../config/i18n/i18n'
import { isTokenExpired } from '../helpers/session'

export type AppContextType = {
  session?: string
  refreshSession?: string
  setSession: React.Dispatch<React.SetStateAction<string | undefined>>
  setRefreshSession: React.Dispatch<React.SetStateAction<string | undefined>>
  // eslint-disable-next-line no-unused-vars
  getSession: (address: string, sign: BrowserProvider) => Promise<void>
  removeSession: () => void
  removeRefreshSession: () => void
  setTokenCookie: (value: string) => void
  setRefreshTokenCookie: (value: string) => void
}

type AppProviderProps = {
  children: React.ReactElement
}

const defaultValues: AppContextType = {
  session: undefined,
  refreshSession: undefined,
  setSession: () => {},
  setRefreshSession: () => {},
  getSession: async () => {},
  removeSession: () => {},
  removeRefreshSession: () => {},
  setTokenCookie: () => {},
  setRefreshTokenCookie: () => {},
}

export const AppContext = React.createContext<AppContextType>(defaultValues)

export function useAppContext() {
  return useContext(AppContext)
}

export function AppProvider(props: AppProviderProps) {
  const { children } = props

  const [session, setSession] = useState(defaultValues.session)
  const [refreshSession, setRefreshSession] = useState(
    defaultValues.refreshSession,
  )
  const [cookieLoaded, setCookieLoaded] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.SNACKBAR])

  const {
    item: token,
    setItem: setTokenCookie,
    removeItem: removeTokenCookie,
  } = useCookie('backend_session')

  const {
    item: refreshTokenCookie,
    setItem: setRefreshTokenCookie,
    removeItem: removeRefreshTokenCookie,
  } = useCookie('refresh_backend_session')

  const removeSession = useCallback(() => {
    removeTokenCookie()
    setSession(undefined)
  }, [removeTokenCookie])

  const removeRefreshSession = useCallback(() => {
    removeRefreshTokenCookie()
    setRefreshSession(undefined)
  }, [removeRefreshTokenCookie])

  const getSession = useCallback(
    async (address: string, browserProvider: BrowserProvider) => {
      try {
        const { nonce: nonceCode } = await nonce({ address })
        const signer = await browserProvider.getSigner()
        const signedMessage = await signer.signMessage(nonceCode)
        if (!signedMessage) throw new Error('message sign cancelled')
        const data = await sign({ sign: signedMessage, address })

        setTokenCookie(data.token)
        setRefreshTokenCookie(data.refresh_token)
        enqueueSnackbar(t(SnackbarTranslations.SESSION_STARTED), {
          variant: 'success',
        })
      } catch (error) {
        enqueueSnackbar(t(SnackbarTranslations.CANNOT_START_SESSION), {
          variant: 'error',
        })
        removeSession()
        removeRefreshSession()
      }
    },
    [
      enqueueSnackbar,
      removeRefreshSession,
      removeSession,
      setRefreshTokenCookie,
      setTokenCookie,
      t,
    ],
  )

  const contextObject: AppContextType = useMemo(
    () => ({
      session,
      setSession,
      refreshSession,
      setRefreshSession,
      getSession,
      removeSession,
      removeRefreshSession,
      setTokenCookie,
      setRefreshTokenCookie,
    }),
    [
      getSession,
      refreshSession,
      removeRefreshSession,
      removeSession,
      session,
      setRefreshTokenCookie,
      setTokenCookie,
    ],
  );

  const handleSessionExpiration = useCallback(async () => {
    if (session && isTokenExpired(session)) {
      if (refreshSession && !isTokenExpired(refreshSession)) {
        // expired access-token
        // TODO: implement refresh token attemp, like AxiosErrorHandler.tsx
      } else {
        // expired refresh-token
        removeSession();
        removeRefreshSession();
      }
    }
  }, [session, refreshSession, removeSession, removeRefreshSession]);

  useEffect(() => {
    if (token) setSession(token);
    if (refreshTokenCookie) setRefreshSession(refreshTokenCookie);
    // Mark the cookie as loaded
    setCookieLoaded(true);
    handleSessionExpiration();
  }, [token, refreshTokenCookie, handleSessionExpiration]);

  return (
    <AppContext.Provider value={contextObject}>
      {cookieLoaded ? children : undefined}
    </AppContext.Provider>
  )
}