import { styled } from '@mui/material'
import { commons } from '../../../images'

export const CharacterButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '200px',
  backgroundRepeat: 'no-repeat',
  transition: 'width .1s ease-out, height .1s ease-out',
  '&:active': {
    height: 'auto',
    width: '105%',
  },
  [theme.breakpoints.down('md')]: {
    height: '100px',
    width: '100px',
    backgroundSize: 'contain',
    '&:active': {
      height: '105px',
      width: '105px',
    },
  },
}))

export const ImageContainer = styled('div')(({ theme }) => ({
  background: `url(${commons.characterContainer}) transparent`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  height: '200px',
  width: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    height: '100px',
    width: '100px',
    backgroundSize: 'contain',
    '&:active': {
      height: '105px',
      width: '105px',
    },
  },
}))

export const ImgGnome = styled('img')(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  width: '60%',
  height: 'auto',
  borderRadius: '20px',
  transition: 'width .1s ease-out, height .1s ease-out',
  '&:active': {
    width: '65%',
    height: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    backgroundSize: 'contain',
    '&:active': {
      height: '61.42px',
      width: '61.42px',
    },
  },
}))
