import React from 'react'
import { MenuItem, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  AppBarTitle,
  CollapsedMenu,
  CollapsedMenuWrapper,
  CustomIconButton,
} from '../AppBar/AppBar.style'
import useDropdown from '../../hooks/useDropdown'
import { MenuAppBarItems } from '../AppBar/constants'

interface CollapsableMenuProps {
  onInitClick: () => void
  onGameplayClick: () => void
  onGnomesClick: () => void
  onRoadmapClick: () => void
}

export function CollapsableMenu({
  onInitClick,
  onGameplayClick,
  onGnomesClick,
  onRoadmapClick,
}: CollapsableMenuProps) {
  const { element, open, close } = useDropdown()
  return (
    <CollapsedMenuWrapper data-testid="collapsed-menu-wrapper-appbar">
      <CustomIconButton
        size="large"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={open}
        color="inherit"
        data-testid="custom-icon-button-appbar"
      >
        {element ? (
          <KeyboardArrowUpIcon
            htmlColor="#000000"
            data-testid="keyboard-arrow-up-dropdown-appbar"
          />
        ) : (
          <KeyboardArrowDownIcon
            htmlColor="#000000"
            data-testid="keyboard-arrow-down-dropdown-appbar"
          />
        )}
        <AppBarTitle data-testid="title-app-bar">Blasted Gnomes</AppBarTitle>
      </CustomIconButton>
      <CollapsedMenu
        anchorEl={element}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!element}
        onClose={close}
        data-testid="menu-collapsed-app-bar"
      >
        <MenuItem onClick={onInitClick}>
          <Typography>{MenuAppBarItems.INICIO}</Typography>
        </MenuItem>
        <MenuItem onClick={onGameplayClick}>
          <Typography>{MenuAppBarItems.GAMEPLAY}</Typography>
        </MenuItem>
        <MenuItem onClick={onGnomesClick}>
          <Typography>{MenuAppBarItems.GNOMES}</Typography>
        </MenuItem>
        <MenuItem onClick={onRoadmapClick}>
          <Typography>{MenuAppBarItems.ROADMAP}</Typography>
        </MenuItem>
      </CollapsedMenu>
    </CollapsedMenuWrapper>
  )
}
