import { Card, Grid, Typography, css, styled } from '@mui/material'
import { GameTypography } from '../../commons/Custom.style'

export const PriceToken = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    font-size: 18px;
    font-weight: 600;
    line-height: initial;
    white-space: nowrap;
  `,
)

export const PriceUsd = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral40};
    font-size: 18px;
    line-height: 24px;
  `,
)

export const InfoTokenSymbol = styled('img')(
  () => css`
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    position: relative;
    top: 4px;
  `,
)

export const PriceWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: 1rem;
  `,
)

export const ItemPriceWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.2rem;
    box-sizing: border-box;
  `,
)

export const ItemNumber = styled('span')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.neutral50};
    font-size: 52px;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -0.52px;
  `,
)

export const ItemDesc = styled('span')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.neutral40};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  `,
)

export const ItemTitle = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.2px;
    text-transform: capitalize;
  `,
)

export const ItemContentWrapperDouble = styled('div')(
  ({ theme }) => css`
    width: 320px;
    height: 152px;
    background-color: ${theme.palette.font.neutral05};
    display: flex;
    flex-flow: column;
    border-radius: 12px;
    padding: 1rem;
    gap: 1rem;
    border: none;
    box-sizing: border-box;
  `,
)

export const ItemContentWrapper = styled('div')(
  ({ theme }) => css`
    width: 152px;
    height: 152px;
    background-color: ${theme.palette.font.neutral05};
    display: flex;
    flex-flow: column;
    border-radius: 12px;
    padding: 1rem;
    border: none;
    box-sizing: border-box;
    overflow: hidden;
    gap: 1rem;
  `,
)

export const CardContentWrapper = styled('div')(
  () => css`
    width: 100%;
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
    box-sizing: border-box;
  `,
)

export const ItemImage = styled('img')(
  ({ theme }) => css`
    width: 100%;
    max-width: 152px;
    height: auto;
    aspect-ratio: 4/4;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.font.neutral10};
    border-radius: 12px;
    border: none;
    box-sizing: border-box;
  `,
)

export const PaginationWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 0;
  `,
)

export const CardActionsWrapper = styled('div')(
  () => css`
    width: 100%;
    margin: 0 auto;
  `,
)

export const ItemsContainer = styled('div')(() => ({
  width: '100%',
  overflowX: 'hidden',
  padding: '0px 50px 50px 50px',
  boxSizing: 'border-box',
}))

export const ContentContainer = styled(Grid)(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin: 0 auto;
  `,
)

export const TypographyColumnContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: '10px',
})

export const TypographyItems = styled(GameTypography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '50px',
  lineHeight: '60px',
  color: theme.palette.font.main,
  fontFamily: 'Gameria',
  maxWidth: '340px',
  [theme.breakpoints.down('md')]: {
    fontSize: '40px',
    lineHeight: '40px',
  },
}))

export const TypographyField = styled(Typography)({
  textTransform: 'capitalize',
})

export const ItemCard = styled(Card)(
  ({ theme }) => css`
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    display: flex;
    gap: 1rem;
    flex-flow: column;
    border: 1px solid ${theme.palette.font.neutral10};
    box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.1);
    box-sizing: border-box;
  `,
)

export const InventoryItemContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))
