import React from 'react'
import { useTranslation } from 'react-i18next'
import { commons, icons } from '../../../images'
import { AddressShorten } from '../AddressShorten/AddressShorten'
import { CommonButton } from '../CommonButton/CommonButton'
import { ButtonValues } from '../../types'
import { CommonTranslations, Translations } from '../../../config'

import {
  AddressAvatar,
  AddressAvatarWrapper,
  AddressWapper,
  BalanceWrapper,
  ButtonWapper,
  Capitalize,
  CopyExplorerWrapper,
  HeaderAccountPop,
  HeaderPopIcon,
  HeaderPopTitle,
  WalletDisconnectContainer,
} from './WalletDisconnect.style'

interface WalletDisconnectProps {
  shown: boolean
  address: string
}

export function WalletDisconnect({ shown, address }: WalletDisconnectProps) {
  const { t } = useTranslation([Translations.COMMON])

  if (shown)
    return (
      <WalletDisconnectContainer>
        <HeaderAccountPop>
          <HeaderPopIcon src={icons.metamask} />
          <HeaderPopTitle>
            {t(CommonTranslations.CONNECTED_WITH)} &nbsp;
            <Capitalize>Metamask</Capitalize>
          </HeaderPopTitle>
        </HeaderAccountPop>

        {/* TO-DO Print balance */}
        <BalanceWrapper>0.112 TLA</BalanceWrapper>

        <AddressWapper>
          <AddressAvatarWrapper>
            <AddressAvatar src={commons.accountAvatar} />
            <AddressShorten address={address ?? ''} />
          </AddressAvatarWrapper>

          <CopyExplorerWrapper>
            <CommonButton
              text=""
              variant={ButtonValues.Square}
              icon={`${icons.link}`}
              clickHandler={() => ''}
            />

            <CommonButton
              text=""
              variant={ButtonValues.Square}
              icon={`${icons.copy}`}
              clickHandler={() => ''}
            />
          </CopyExplorerWrapper>
        </AddressWapper>

        <ButtonWapper>
          <CommonButton
            variant={ButtonValues.Secondary}
            text="Desconectar"
            clickHandler={() => ''}
            full
          />
        </ButtonWapper>
      </WalletDisconnectContainer>
    )

  return null
}
