import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppContext } from '../commons/contexts/AppContext'
import { RoutesEnum } from './constants'

type ProtectedRouteProps = {
  children: React.ReactElement
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { children } = props
  const { session } = useAppContext()
  const location = useLocation()

  if (!session) {
    return (
      <Navigate
        to={RoutesEnum.LOGIN}
        state={{ from: location.pathname }}
        replace
      />
    )
  }
  return children
}
