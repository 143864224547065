import React from 'react'
import { IconButton, Link, Menu, MenuItem, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'

export function MobileAppBar({
  routes,
}: {
  routes: { id: string; path: string; title: string }[]
}) {
  const [anchorElNav, setAnchorElNav] = React.useState<HTMLElement | null>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <>
      <IconButton
        data-testid="menu-icon"
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {routes.map(page => (
          <Link
            data-testid={`menu-item-${page.id}`}
            key={page.id}
            component={RouterLink}
            to={page.path}
            color="black"
            underline="none"
            variant="button"
          >
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography textAlign="center">{page.title}</Typography>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  )
}
