import { useState, MouseEvent } from 'react'

const useDropdown = () => {
  const [element, setElement] = useState<null | HTMLElement>(null)

  const open = (event: MouseEvent<HTMLElement>) =>
    setElement(event.currentTarget)
  const close = () => setElement(null)

  return { element, open, close }
}

export default useDropdown
