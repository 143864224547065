import React from 'react'
import SwipeableViews from 'react-swipeable-views-react-18-fix'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

interface CarouselProps {
  children: React.ReactNode
}

export function Carousel({ children }: CarouselProps) {
  return (
    <AutoPlaySwipeableViews
      axis="x"
      data-testid="carousel:autoplay-swipeable-views"
    >
      {children}
    </AutoPlaySwipeableViews>
  )
}
