import React from 'react'
import { useTranslation } from 'react-i18next'
import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
/* import { useWalletContext } from '../../commons/contexts/WalletContext' */
import { Translations, CommonTranslations } from '../../config/i18n/i18n'
/* import { Buy } from './components/Buy' */
import { CommonTitle } from '../../commons/components/CommonTitle/CommonTitle'
import { ChestCard } from './components/ChestCard'
import { ModalCommon } from '../../commons/components/ModalCommon/ModalCommon'
import { ModalOpenChest } from './components/ModalOpenChest'

import { ChestContainer, ChestWrapper, ContentContainer } from './Chest.style'

export function Chest() {
  const { t } = useTranslation([Translations.COMMON])
  /* const { tokenId } = useWalletContext() */

  const showModal = false

  return (
    <ChestContainer data-testid="chest:page-container">
      <DashboardAppBar />

      <ContentContainer data-testid="chest:content-container">
        <CommonTitle
          title={`${t(CommonTranslations.CHESTS)}`}
          data-testid="chest:typography-column-container"
        />

        <ChestWrapper>
          <ChestCard />
          {/* {tokenId ? <ChestCard /> : <Buy />} */}
        </ChestWrapper>
      </ContentContainer>

      {/* TO-DO Modal as example - remove modal and children */}
      <ModalCommon isShown={showModal} closeModal={() => ''}>
        <ModalOpenChest />
      </ModalCommon>
    </ChestContainer>
  )
}
