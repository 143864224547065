import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { Carousel } from '../Carousel'
import { CarouselContainer, DesktopContainer } from './ResponsiveCarousel.style'

interface ResponsiveCarouselProps {
  children: React.ReactNode
}

export function ResponsiveCarousel({ children }: ResponsiveCarouselProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <CarouselContainer data-testid="roadmap:carousel-container">
      <Carousel>
        {isMobile ? (
          children
        ) : (
          <DesktopContainer data-testid="responsive-carousel:desktop-container">
            {children}
          </DesktopContainer>
        )}
      </Carousel>
    </CarouselContainer>
  )
}
