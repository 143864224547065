import { NavLink } from 'react-router-dom'
import { css, styled } from '@mui/material'
import { ButtonValues } from '../../types'

interface ButtonProps {
  variant: ButtonValues
  small?: boolean
}

export const IconButtonSmall = styled('img')(
  () => css`
    width: 100%;
    max-width: 18px;
    height: auto;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)

export const IconButton = styled('img')(
  () => css`
    width: 100%;
    max-width: 40px;
    height: auto;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)

export const LinkTo = styled(NavLink)(
  () => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border: 0;
  `,
)
export const ButtonContainer = styled('button')<ButtonProps>(
  ({ theme, variant, small }) => css`
    display: flex;
    height: 48px;
    padding: 8px 12px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
    border: none;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    line-height: 24px;
    border-radius: 12px;
    gap: 0.5rem;
    position: relative;

    ${variant === ButtonValues.Primary &&
    css`
      background-color: ${theme.palette.font.warningDark};
      color: ${theme.palette.font.main};
      border: 1px solid transparent;
    `}

    ${variant === ButtonValues.Secondary &&
    css`
      background-color: ${theme.palette.font.warningLight};
      color: ${theme.palette.font.warning};
      border: 1px solid transparent;
    `}

    ${variant === ButtonValues.White &&
    css`
      background-color: ${theme.palette.font.main};
      color: ${theme.palette.font.neutral50};
      border: 1px solid ${theme.palette.font.neutral01};
    `}

  ${variant === ButtonValues.Square &&
    css`
      width: 40px;
      height: 40px;
      background-color: ${theme.palette.font.main};
      color: ${theme.palette.font.neutral50};
      border: 1px solid ${theme.palette.font.neutral10};
    `}

  ${variant === ButtonValues.Transparent &&
    css`
      background-color: transparent;
      color: ${theme.palette.font.main};
      border: 1px solid transparent;
    `}

  ${small &&
    css`
      height: 32px;
      border-radius: 8px;
    `}



    &:hover {
      cursor: pointer;
    }

    &:disabled {
      background-color: ${theme.palette.font.neutral05};
      color: ${theme.palette.font.neutral20};
      border: 1px solid ${theme.palette.font.neutral05};
      pointer-events: none;
    }
  `,
)
