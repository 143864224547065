import { Grid, styled, Box, Typography } from '@mui/material'
import { Cloud } from '../Roadmap/Roadmap.style'

export const TeamSectionContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  height: '700px',
  width: '100%',
  gridTemplateRows: 'minmax(200px, 30%) minmax(400px, auto)',
  alignItems: 'flex-start',
  placeItems: 'center',
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    gridTemplateRows: 'minmax(200px, 30%) auto ',
    height: 'auto',
    overflowX: 'auto',
  },
}))

export const TeamSectionTitleContainer = styled(Grid)({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  gridTemplateColumns: 'repeat(3, 33%)',
  placeItems: 'center',
})

export const TeamWorkerCards = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: '25px',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const TeamCloud = styled(Cloud)(({ theme }) => ({
  marginRight: 'auto',
  transform: 'translateX(-200px) translateY(-40px)',
  zIndex: 0,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const TeamWorkerTextContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const TypographyWorker = styled(Typography)({
  fontSize: '2.5rem',
})

export const WoodLetterContainer = styled('div')(() => ({
  gridColumnStart: '2',
  height: 'auto',
  width: 'auto',
  zIndex: 1,
}))
