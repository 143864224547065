import React from 'react'
import { AppBar } from '../AppBar'
import { MenuLayoutGrid } from './MenuWrapper.style'

interface MenuWrapperProps {
  children: React.ReactNode
}

export function MenuWrapper({ children }: MenuWrapperProps) {
  return (
    <MenuLayoutGrid data-testid="menu-wrapper-grid">
      <AppBar />
      {children}
    </MenuLayoutGrid>
  )
}
