import React from 'react'
import { WoodLetterContainer, WoodLetterTypography } from './WoodLetter.style'

interface WoodLetterProps {
  label: string
}

export function WoodLetter({ label }: WoodLetterProps) {
  return (
    <WoodLetterContainer>
      <WoodLetterTypography>{label}</WoodLetterTypography>
    </WoodLetterContainer>
  )
}
