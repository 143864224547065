import { styled } from '@mui/material'

export const AppBarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '100vw',
  transform: 'translateY(70px)',
  zIndex: 2,
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
}))
