import React from 'react'

import { icons } from '../../../images'
import {
  ModalCommonContainer,
  DescriptionTextModal,
  DescriptionTextModalContainer,
  TitleTextModal,
  CloseWrapper,
  CloseIcon,
} from './ModalCommon.styled'

interface ModalCommonProps {
  isShown: boolean
  title?: string | JSX.Element
  text?: string | JSX.Element
  children?: React.ReactElement
  closeModal: () => void
}

export function ModalCommon({
  isShown,
  title,
  text,
  children,
  closeModal,
}: ModalCommonProps) {
  if (isShown) {
    return (
      <ModalCommonContainer>
        <DescriptionTextModalContainer>
          <CloseWrapper onClick={closeModal}>
            <CloseIcon src={icons.cross} />
          </CloseWrapper>

          {title && <TitleTextModal>{title}</TitleTextModal>}

          {text && <DescriptionTextModal>{text}</DescriptionTextModal>}

          {children}
        </DescriptionTextModalContainer>
      </ModalCommonContainer>
    )
  }

  return null
}
