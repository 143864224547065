import { Typography } from '@mui/material'
import React from 'react'
import {
  RoadmapCardContainer,
  RoadmapCardTitle,
  RoadmapDivider,
  RoadmapList,
  RoadmapListItem,
  RoadmapTypography,
} from './Roadmap.style'

interface RoadmapCardProps {
  q: string
  year: string
  list: string[]
}

export function RoadmapCard({ q, year, list }: RoadmapCardProps) {
  return (
    <RoadmapCardContainer data-testid="roadmap-card:container">
      <RoadmapCardTitle data-testid="roadmap-card:title">
        <RoadmapTypography fontWeight="400">Q{q}</RoadmapTypography>
        <RoadmapTypography fontWeight="bold">{year}</RoadmapTypography>
      </RoadmapCardTitle>
      <RoadmapDivider data-testid="roadmap-card:divider" />
      <RoadmapList data-testid="roadmap-card:list">
        {list.map((item, index) => (
          <RoadmapListItem
            data-testid="roadmap-card:list-item"
            key={`${item}_${index}`}
          >
            <Typography>{item}</Typography>
          </RoadmapListItem>
        ))}
      </RoadmapList>
    </RoadmapCardContainer>
  )
}
