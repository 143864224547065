import { Grid, Typography, styled } from '@mui/material'

export const GridContainer = styled(Grid)({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  placeItems: 'center',
})

interface LoadingTypographyProps {
  loading: boolean
}

export const GameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Onacona',
  fontWeight: '800',
  textShadow: '-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black',
  letterSpacing: '2.5px',
  color: theme.palette.font?.main,
  textTransform: 'capitalize',
}))

export const LoadingTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'loading',
})<LoadingTypographyProps>(({ loading }) => ({
  ...(loading
    ? {
        clipPath: 'inset(0 1ch 0 0)',
        animation: 'l 1s steps(4) infinite',
        '@keyframes l': {
          to: {
            clipPath: 'inset(0 -1ch 0 0)',
          },
        },
      }
    : {}),
}))
