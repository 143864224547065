import React, { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { SnackbarTranslations, Translations } from '../../../config/i18n/i18n'
import { Gnome as GnomeType } from '../../models/Gnome'
import { fetchGnome } from '../../../services/backend'
import { getAttributes } from '../../helpers/utils'
import { GnomeCard } from '../GnomeCard'

export function Gnome({
  gnomeId,
  actionsComponents,
  loading,
}: {
  gnomeId: string
  loading?: boolean
  actionsComponents?: React.ReactNode
}) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.SNACKBAR])
  const [gnome, setGnome] = useState<GnomeType | undefined>()

  const getGnome = useCallback(async () => {
    try {
      const data = await fetchGnome(gnomeId)

      const attributes = getAttributes(data)

      setGnome({ ...data, metadata: { ...data.metadata, attributes } })
    } catch {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_GNOME), {
        variant: 'error',
      })
      setGnome(undefined)
    }
  }, [enqueueSnackbar, gnomeId, t])

  useEffect(() => {
    getGnome()
  }, [getGnome])

  return (
    <GnomeCard
      tier={gnome?.metadata?.attributes?.tier}
      level={gnome?.metadata?.attributes?.level}
      xp={gnome?.metadata?.attributes?.xp}
      image={gnome?.metadata?.image}
      actionsComponents={actionsComponents}
      loading={loading}
    />
  )
}
