import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, TOKEN_SYMBOL, Translations } from '../../../config'

import { ButtonValues, Gnome } from '../../types'
import { UpLevelTag } from './Components/UpLevelTag/UpLevelTag'
import { CommonButton } from '../CommonButton/CommonButton'
import { commons, icons } from '../../../images'
import { GnomeTag } from '../GnomeTag/GnomeTag'

import {
  BodyInfoWrapper,
  CardBodyWrapper,
  CardHeaderImage,
  CardHeaderWrapper,
  GnomeCardNewContainer,
  GnomeId,
  GnomeSkill,
  GnomeSkillsWrapper,
  HeaderOptionsTeam,
  HeaderPriceInfo,
  HeaderPriceWrapper,
  InfoTokenSymbol,
  PriceToken,
  PriceWrapper,
} from './GnomeCardNew.style'

interface GnomeCardNewProps {
  gnome: Gnome
  gnomeTeam?: boolean
  showGnome?: () => void
}

export function GnomeCardNew({
  gnome,
  gnomeTeam,
  showGnome,
}: GnomeCardNewProps) {
  const { t } = useTranslation([Translations.COMMON])

  return (
    <GnomeCardNewContainer>
      <CardHeaderWrapper>
        <CardHeaderImage src={gnome.metadata.image} />

        {gnomeTeam ? (
          <HeaderOptionsTeam>
            <CommonButton
              text={t(CommonTranslations.LEVEL_UP)}
              clickHandler={() => ''}
              disabled={!gnome.can_level_up}
              full
            />

            <CommonButton
              text={t(CommonTranslations.SEE_ON_MARKETPLACE)}
              iconSmall={icons.linkWhite}
              clickHandler={showGnome}
              variant={ButtonValues.Transparent}
              full
            />
          </HeaderOptionsTeam>
        ) : (
          <HeaderPriceWrapper>
            <HeaderPriceInfo>
              <PriceWrapper>
                <InfoTokenSymbol src={commons.lacSymbol} />
                <PriceToken>
                  {`${gnome?.metadata?.price_lac} ${TOKEN_SYMBOL}`}
                </PriceToken>
              </PriceWrapper>
            </HeaderPriceInfo>
          </HeaderPriceWrapper>
        )}
      </CardHeaderWrapper>

      <CardBodyWrapper>
        <GnomeTag gnomeType={gnome.metadata.gnome_type} />

        <BodyInfoWrapper>
          <GnomeId>{`#${gnome.id}`}</GnomeId>

          <GnomeSkillsWrapper>
            {gnome.metadata.attributes?.map(att => (
              <GnomeSkill key={att.value}>
                {`${att.trait_type}: ${att.value}`}
              </GnomeSkill>
            ))}

            {gnome.can_level_up && gnomeTeam && <UpLevelTag />}
          </GnomeSkillsWrapper>
        </BodyInfoWrapper>
      </CardBodyWrapper>
    </GnomeCardNewContainer>
  )
}
