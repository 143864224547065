import React from 'react'
import {
  CharactersButtonsContainer,
  DescriptionContainer,
  GnomePreviewContainer,
  GnomesSectionContainer,
  TypographyDetail,
} from './Gnomes.style'
import { WoodLetter } from '../../../../commons/components/WoodLetter'
import { CharacterButton } from '../../../../commons/components/CharacterButton'
import { GnomePreview } from '../../../../commons/components/GnomePreview'

export function GnomesSection() {
  return (
    <GnomesSectionContainer
      id="gnomes-section"
      data-testid="gnomes-section:gnomes-section-container"
    >
      <GnomePreviewContainer data-testid="gnome-section:gnome-preview-container">
        <GnomePreview />
      </GnomePreviewContainer>
      <DescriptionContainer data-testid="gnomes-section:description-container">
        <WoodLetter label="Gnomes" />
        <TypographyDetail>
          Arma tu equipo con los gnomos NFT que puedes adquirir, integrarlos a
          tu equipo para la batalla y luego vender en cualquier momento. Existen
          diversos de ellos con sus estéticas y habilidades!
        </TypographyDetail>
        <CharactersButtonsContainer data-testid="gnomes-section:character-buttons-container">
          {Array.from({ length: 6 }, (_, index) => (
            <CharacterButton key={index} />
          ))}
        </CharactersButtonsContainer>
      </DescriptionContainer>
    </GnomesSectionContainer>
  )
}
