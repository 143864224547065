import { styled } from '@mui/material'

export const DesktopContainer = styled('div')({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  gap: '40px',
  justifyContent: 'center',
})

export const CarouselContainer = styled('div')(() => ({
  display: 'flex',
  placeItems: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}))
