import React, { useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { SnackbarTranslations, Translations } from '../../../config/i18n/i18n'
import { Tournament } from '../../../pages/Prize/models/Tournament'
import { Prize } from '../../../pages/Prize/models/Prize'
import { fetchPrizes } from '../../../services/backend'
import { Carousel } from '../Carousel'
import { CarouselContainer } from '../../../pages/Prize/Prize.style'
import { GnomeContainer } from '../GnomeContainer/GnomeContainer'
import { PrizeCards } from '../PrizeCards/PrizeCards'

export function TournamentPrizes({ tournament }: { tournament: Tournament }) {
  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])
  const [prizes, setPrizes] = useState<Prize[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleGetPrizes = async () => {
    try {
      const data = await fetchPrizes({
        tournament: tournament.id,
        limit: 10,
        ordering: 'position',
      })
      setPrizes([...data.results])
    } catch (error) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_PRIZES), {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    handleGetPrizes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament, enqueueSnackbar, t])

  return isMobile ? (
    <CarouselContainer data-testid="prize:carousel-container">
      <Carousel>{PrizeCards({ prizes })}</Carousel>
    </CarouselContainer>
  ) : (
    <GnomeContainer>{PrizeCards({ prizes })}</GnomeContainer>
  )
}
