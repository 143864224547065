import React from 'react'
import {
  TeamCloud,
  TeamSectionContainer,
  TeamSectionTitleContainer,
  TeamWorkerCards,
  WoodLetterContainer,
} from './Team.style'
import { WoodLetter } from '../../../../commons/components/WoodLetter'
import { TeamCarousel } from '../../../../commons/components/TeamCarousel/TeamCarousel'
import { commons } from '../../../../images'

export function TeamSection() {
  return (
    <TeamSectionContainer data-testid="team-section:container">
      <TeamSectionTitleContainer data-testid="team-section:title">
        <WoodLetterContainer data-testid="team-section:wood-letter-container">
          <WoodLetter label="Team" />
        </WoodLetterContainer>
        <TeamCloud
          src={commons.cloudMiddle}
          data-testid="team:cloud-up-image"
          alt="cloud_middle"
        />
      </TeamSectionTitleContainer>
      <TeamWorkerCards data-testid="team-section:worker-cards">
        <TeamCarousel />
      </TeamWorkerCards>
    </TeamSectionContainer>
  )
}
