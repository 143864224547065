import React from 'react'
import { BarNavLink, BarNavLinkWrapper } from './DashboardAppBar.style'

interface DesktopAppBarProps {
  routes: { id: string; path: string; title: string }[]
}

export function DesktopAppBar({ routes }: DesktopAppBarProps) {
  return (
    <BarNavLinkWrapper>
      {routes.map(page => (
        <BarNavLink
          data-testid={`menu-item-${page.id}`}
          className={page.path === page.id ? 'active' : ''}
          key={page.id}
          to={page.path}
        >
          {page.title}
        </BarNavLink>
      ))}
    </BarNavLinkWrapper>
  )
}
