import { Button, Divider, Grid, Typography, styled } from '@mui/material'
import { GameTypography } from '../../Custom.style'
import { commons } from '../../../images'

export const FooterContainer = styled(Grid)(({ theme }) => ({
  height: '752px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${commons.brownWaves})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  paddingTop: '50px',
  paddingLeft: '10%',
  paddingRight: '10%',
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const FooterContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '50% auto 30%',
  alignItems: 'flex-end',
  height: '80%',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const FooterLogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const CustomContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
})

export const FooterLogoBlasted = styled('img')(({ theme }) => ({
  width: '310px',
  height: '214px',
  userSelect: 'none',
  pointerEvents: 'none',
  [theme.breakpoints.down('lg')]: {
    width: '270px',
    height: '192.58px',
  },
  [theme.breakpoints.down('md')]: {
    width: '250px',
    height: '172.58px',
  },
}))

export const TitleAndButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
}))

export const LoremName = styled(GameTypography)(({ theme }) => ({
  color: theme.palette.font.secondary,
  fontSize: '38px',
  display: 'flex',
  textAlign: 'center',
  marginRight: '20px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '28px',
  },
}))

export const TransparentButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
  },
}))

export const TransparentButton = styled(Button)(({ theme }) => ({
  background: 'unset',
  border: 'unset',
  width: '205px',
  height: '34px',
  fontSize: '17px',
  color: theme.palette.font.main,
  borderRadius: 'unset',
  ':hover': {
    color: theme.palette.font.main,
  },
  [theme.breakpoints.down('lg')]: {
    width: '100px',
  },
}))

export const FooterDivider = styled(Divider)(({ theme }) => ({
  marginTop: '20px',
  marginBottom: '20px',
  color: 'white',
  borderColor: theme.palette.background.paper,
}))

export const FooterFinePrintLogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingBottom: '10px',
  },
}))

export const LogoFooterRipio = styled('img')(({ theme }) => ({
  width: '100px',
  height: 'auto',
  userSelect: 'none',
  flex: 0.3,
  [theme.breakpoints.up('md')]: {
    marginLeft: '100px',
  },
  [theme.breakpoints.down('md')]: {
    width: '75px',
  },
}))

export const FinePrint = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  color: theme.palette.font.main,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}))
