import { Grid, styled } from '@mui/material'
import { commons } from '../../../../images'

export const RoadmapSectionContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '800px',
  width: '100%',
  alignItems: 'center',
  gap: '40px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    minHeight: '100%',
    maxWidth: '100%',
    gap: '40px',
  },
}))

export const Cloud = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100px',
    height: 'auto',
    objectFit: 'contain',
    display: 'none',
  },
}))

export const CustomRoadmapTitleContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 33.3%)',
  placeItems: 'center',
  width: '100%',
  alignItems: 'flex-end',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    gridTemplateColumns: 'unset',
    placeItems: 'unset',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const RoadmapTitleContainer = styled(Grid)(() => ({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  background: `url(${commons.gnomesWood})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '84px',
  width: '364px',
}))

export const CarouselContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  placeItems: 'center',
  alignItems: 'center',
  height: '100%',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    height: '100%',
    width: '281px',
    overflow: 'hidden',
  },
}))
