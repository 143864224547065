import React from 'react'
import { Navigate } from 'react-router-dom'
import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { Play } from '../pages/Play'
import { Chest } from '../pages/Chest'
import { WalletProvider } from '../commons/contexts/WalletContext'
import { RoutesEnum } from './constants'
import { GnomesTeam } from '../pages/GnomesTeam'
import { Marketplace } from '../pages/Marketplace'
import { Prize } from '../pages/Prize'
import ProtectedRoute from './ProtectedRoute'
import { Inventory } from '../pages/Inventory'

export const routes = [
  {
    path: RoutesEnum.BASE,
    element: <Home />,
  },
  {
    path: RoutesEnum.BASE,
    element: <WalletProvider router />,
    children: [
      {
        path: RoutesEnum.LOGIN,
        element: <Login />,
      },
      {
        path: RoutesEnum.CHEST,
        element: (
          <ProtectedRoute>
            <Chest />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutesEnum.PLAY,
        element: (
          <ProtectedRoute>
            <Play />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutesEnum.TEAM,
        element: (
          <ProtectedRoute>
            <GnomesTeam />
          </ProtectedRoute>
        ),
      },
      {
        path: RoutesEnum.MARKETPLACE,
        element: <Marketplace />,
      },
      {
        path: RoutesEnum.PRIZES,
        element: <Prize />,
      },
      {
        path: RoutesEnum.INVENTORY,
        element: <Inventory />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={RoutesEnum.BASE} />,
  },
]
