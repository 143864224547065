import React from 'react'
import { Paper, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TypographyColumnContainer } from '../../../pages/Prize/Prize.style'
import { CommonTranslations, Translations } from '../../../config/i18n/i18n'
import { Gnome } from '../Gnome'
import { Prize } from '../../../pages/Prize/models/Prize'
import { hashShortener } from '../../helpers/utils'
import { TOKEN_SYMBOL } from '../../../config'

export function PrizeCards({ prizes }: { prizes: Prize[] }) {
  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])

  return prizes.map(prize => (
    <Paper
      elevation={12}
      key={prize.id}
      sx={{
        width: '281px',
        height: '451px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <TypographyColumnContainer>
        <Typography variant="overline">
          {`${t(CommonTranslations.POSITION)}: ${prize.position}`}
        </Typography>
        <Typography variant="overline">
          {`${t(CommonTranslations.PRIZE)}: ${prize.lac_prize} ${TOKEN_SYMBOL}`}
        </Typography>
        {prize.nft_prize && (
          <Gnome gnomeId={prize.nft_prize.split('/').reverse()[1]} />
        )}
        <Tooltip title={prize.ranking?.tx_hash}>
          <Typography noWrap variant="overline">
            {`${t(CommonTranslations.TRANSACTION_HASH)}: ${hashShortener(
              prize.ranking?.tx_hash,
            )}`}
          </Typography>
        </Tooltip>
        <Tooltip title={prize.ranking?.tx_nft_hash}>
          <Typography noWrap variant="overline">
            {`${t(CommonTranslations.NFT_TRANSACTION_HASH)}: ${hashShortener(
              prize.ranking?.tx_nft_hash,
            )}`}
          </Typography>
        </Tooltip>
      </TypographyColumnContainer>
    </Paper>
  ))
}
