import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../../config'
import { icons } from '../../../../../images'
import { TagValues } from '../../../../types'

import { UpLevelContainer, UpLevelIcon, UpLevelText } from './UpLevelTag.style'

interface UpLevelTagProps {
  variant?: TagValues
}

export function UpLevelTag({
  variant = TagValues.CanLevelUp,
}: UpLevelTagProps) {
  const { t } = useTranslation([Translations.COMMON])

  if (variant === TagValues.InfoLevelUp)
    return (
      <UpLevelContainer variant={variant}>
        <UpLevelIcon src={icons.infoBlueIcon} />
        <UpLevelText>{t(CommonTranslations.UP_LEVEL_GNOME)}</UpLevelText>
      </UpLevelContainer>
    )

  return (
    <UpLevelContainer variant={variant}>
      <UpLevelIcon src={icons.upArrow} />
      <UpLevelText>{t(CommonTranslations.UP_LEVEL_GNOME)}</UpLevelText>
    </UpLevelContainer>
  )
}
