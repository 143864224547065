import { BrowserProvider, Eip1193Provider, ethers, TransactionReceipt, TransactionResponse } from 'ethers'
import { BigNumber } from 'bignumber.js'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSnackbar } from 'notistack'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  GNOMES_CONTRACT_ADDRESS,
  IAP_CONTRACT_ADDRESS,
  MARKETPLACE_CONTRACT_ADDRESS,
} from '../../config'
import collectionAbi from '../../abis/collection.json'
import iapAbi from '../../abis/purchase.json'
import marketplaceAbi from '../../abis/marketplace.json'
import { SnackbarTranslations, Translations } from '../../config/i18n/i18n'
import { isTxRejected } from '../helpers/tx'

export interface WalletContextType {
  collectionSdk: ethers.Contract | undefined
  marketContract: ethers.Contract | undefined
  browserProvider: BrowserProvider | undefined
  iapContract: ethers.Contract | undefined
  address: undefined | string
  chestPrice: string | undefined
  tokenId: string | undefined
  clearTokenId: () => void
  getChestPrice: () => Promise<void>
  buyChest: () => Promise<void>
  buyItem: (itemId: number, price: string) => Promise<void>
  listItemToMarketplace: (tokenId: string, price: BigNumber) => Promise<void>
  removeItemFromMarketplace: (tokenId: string) => Promise<void>
  buyMarketplaceGnome: (_itemId: string, _price: string) => Promise<void>
}
export interface WalletProviderProps {
  children?: React.ReactNode
  router?: boolean
}

const defaultValues: WalletContextType = {
  collectionSdk: undefined,
  marketContract: undefined,
  browserProvider: undefined,
  iapContract: undefined,
  address: undefined,
  chestPrice: undefined,
  tokenId: undefined,
  clearTokenId: () => {},
  getChestPrice: async () => {},
  buyChest: async () => {},
  buyItem: async () => {},
  listItemToMarketplace: async () => {},
  removeItemFromMarketplace: async () => {},
  buyMarketplaceGnome: async () => {},
}

export const WalletContext = createContext<WalletContextType>(defaultValues)

export function useWalletContext() {
  return useContext(WalletContext)
}

export function WalletProvider({ children, router }: WalletProviderProps) {
  const { t } = useTranslation([Translations.SNACKBAR])
  const [collectionSdk, setCollectionSdk] = useState<ethers.Contract | undefined>(undefined)

  const [iapContract, setIapContract] = useState<ethers.Contract | undefined>(undefined)

  const [browserProvider, setBrowserProvider] = useState<ethers.BrowserProvider | undefined>(undefined)

  const [marketContract, setMarketContract] = useState<ethers.Contract | undefined>(undefined)

  const [address, setAddress] = useState<string | undefined>(
    defaultValues.address,
  )
  const [chestPrice, setChestPrice] = useState<string | undefined>(
    defaultValues.chestPrice,
  )
  const [tokenId, setTokenId] = useState<string | undefined>(
    defaultValues.tokenId,
  )
  const { enqueueSnackbar } = useSnackbar()

  const getChestPrice = useCallback(async () => {
    try {
      const price: BigNumber = await iapContract?.getChestPrice()
      setChestPrice(price?.toString())
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.CANNOT_GET_CHEST), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, iapContract, t])

  const buyChest = useCallback(async () => {
    try {
      const ifaceCollection = new ethers.Interface(collectionAbi)
      const price = await iapContract?.getChestPrice()
      const tx = await iapContract?.buyChest({ value: price.toString() })
      const receipt: TransactionReceipt = await tx.wait()
  
      receipt.logs.forEach(log => {
        try {
          const logParsed = ifaceCollection.parseLog(log)
          if (logParsed?.name === 'TransferRandom') {
            // eslint-disable-next-line no-underscore-dangle
            const gotTokenId = logParsed.args._tokenId
            setTokenId(gotTokenId.toString())
          }
        } catch {
          // TODO: handle error
        }
      })
    } catch (error) {
      if (isTxRejected(error)) return
      enqueueSnackbar(t(SnackbarTranslations.CANNOT_BUY_CHEST), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, t, iapContract])

  const clearTokenId = useCallback(() => {
    setTokenId(defaultValues.tokenId)
  }, [])

  const listItemToMarketplace = useCallback(
    async (_tokenId: string, price: BigNumber) => {
      if (!marketContract || !collectionSdk) return
  
      try {
        const approvalTx: TransactionResponse = await collectionSdk.approve(marketContract.address, _tokenId)
        await approvalTx.wait()
  
        const value = ethers.parseEther(price.toString()) // price in wei
        const saleTx: TransactionResponse = await marketContract.createSale(_tokenId, value)
        await saleTx.wait()
      } catch (error) {
        if (isTxRejected(error)) return
        enqueueSnackbar(t(SnackbarTranslations.FAIL_TO_LIST_ITEM), {
          variant: 'error',
        })
      }
    },
    [marketContract, collectionSdk, enqueueSnackbar, t]
  )

  const removeItemFromMarketplace = useCallback(
    async (_markeplaceId: string) => {
      if (!marketContract) return
      try {
        const tx: TransactionResponse = await marketContract.cancelSale(_markeplaceId)
        await tx.wait()
      } catch (error) {
        if (isTxRejected(error)) return
        enqueueSnackbar(t(SnackbarTranslations.FAIL_TO_REMOVE_ITEM), {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar, marketContract, t],
  )

  const buyItem = useCallback(
    async (itemId: number, price: string) => {
      try {
        const tx: TransactionResponse = await iapContract?.buyItem(itemId, { value: ethers.parseEther(price) })
        await tx.wait()
        enqueueSnackbar(t(SnackbarTranslations.BOUGHT_ITEM), {
          variant: 'success',
        })
      } catch (error) {
        if (isTxRejected(error)) return
        enqueueSnackbar(t(SnackbarTranslations.CANNOT_BUY_ITEM))
      }
    },
    [enqueueSnackbar, t, iapContract],
  )

  const buyMarketplaceGnome = useCallback(
    async (_itemId: string, _price: string) => {
      try {
        if (!marketContract) return

        const value = ethers.parseEther(_price) // price in wei
        const tx: TransactionResponse = await marketContract.buy(_itemId, { value })
        await tx?.wait()
      } catch (error) {
        if (isTxRejected(error)) return
        enqueueSnackbar(t(SnackbarTranslations.CANNOT_BUY_GNOME), {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar, marketContract, t],
  )

  const value = useMemo<WalletContextType>(
    () => ({
      collectionSdk,
      browserProvider,
      iapContract,
      address,
      chestPrice,
      tokenId,
      marketContract,
      clearTokenId,
      getChestPrice,
      buyChest,
      listItemToMarketplace,
      buyItem,
      removeItemFromMarketplace,
      buyMarketplaceGnome,
    }),
    [
      collectionSdk,
      browserProvider,
      iapContract,
      address,
      chestPrice,
      tokenId,
      marketContract,
      clearTokenId,
      getChestPrice,
      buyChest,
      listItemToMarketplace,
      buyItem,
      removeItemFromMarketplace,
      buyMarketplaceGnome,
    ],
  )

  const setIapContractInstance = useCallback(async (provider: BrowserProvider) => {
    try {
      if (!provider) return
      const signer = await provider.getSigner()
      const iapContractInstance = new ethers.Contract(IAP_CONTRACT_ADDRESS, iapAbi, signer)
      setIapContract(iapContractInstance)
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_CONNECT_TO_CONTRACT))
    }
  }, [enqueueSnackbar, t])

  const setMkpContract = useCallback(async () => {
    try {
      if (!MARKETPLACE_CONTRACT_ADDRESS) return // TODO: add throw

      const signer = await browserProvider?.getSigner()
      const mkpInstance = new ethers.Contract(MARKETPLACE_CONTRACT_ADDRESS, marketplaceAbi, signer)
      setMarketContract(mkpInstance)
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_CONNECT_TO_CONTRACT), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, t, browserProvider])

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const setContract = useCallback(async (provider: BrowserProvider) => {
    try {
      const signer = await provider?.getSigner()
      const contractInstance = new ethers.Contract(
        GNOMES_CONTRACT_ADDRESS,
        collectionAbi,
        signer
      )
  
      setCollectionSdk(contractInstance)
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_CONNECT_TO_CONTRACT), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, t])

  const activate = useCallback(async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum as Eip1193Provider)
      await provider.send('eth_requestAccounts', [])
      const signer = await provider.getSigner()
      const walletAddress = await signer.getAddress()

      setBrowserProvider(provider)
      setAddress(walletAddress)

      await setIapContractInstance(provider)

      if (GNOMES_CONTRACT_ADDRESS) await setContract(provider)
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_CONNECT_TO_WALLET))
    }
  }, [enqueueSnackbar, t, setIapContractInstance, setContract])

  useEffect(() => {
    activate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (address) setMkpContract()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return (
    <WalletContext.Provider value={value}>
      {router ? <Outlet /> : children}
    </WalletContext.Provider>
  )
}
