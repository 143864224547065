import React from 'react'
import {
  AppBarMenuButton,
  AppBarMenuWrapper,
  BlastedAppBarIcon,
} from '../AppBar/AppBar.style'
import { logo } from '../../../images'
import {
  AppBarTypography,
  ContainerSizeMenuLeft,
  ContainerSizeMenuRight,
} from './HorizontalMenu.style'
import { MenuAppBarItems } from '../AppBar/constants'

interface HorizontalMenuProps {
  onInitClick: () => void
  onGameplayClick: () => void
  onGnomesClick: () => void
  onRoadmapClick: () => void
}

export function HorizontalMenu({
  onInitClick,
  onGameplayClick,
  onGnomesClick,
  onRoadmapClick,
}: HorizontalMenuProps) {
  return (
    <AppBarMenuWrapper data-testid="app-bar-menu-wrapper">
      <ContainerSizeMenuLeft data-testid="horizontal-menu:container-left">
        <AppBarMenuButton onClick={onInitClick}>
          <AppBarTypography>{MenuAppBarItems.INICIO}</AppBarTypography>
        </AppBarMenuButton>

        <AppBarMenuButton onClick={onGameplayClick}>
          <AppBarTypography>{MenuAppBarItems.GAMEPLAY}</AppBarTypography>
        </AppBarMenuButton>
      </ContainerSizeMenuLeft>

      <BlastedAppBarIcon src={logo.blastedGnomes} />

      <ContainerSizeMenuRight data-testid="horizontal-menu:container-right">
        <AppBarMenuButton onClick={onGnomesClick}>
          <AppBarTypography>{MenuAppBarItems.GNOMES}</AppBarTypography>
        </AppBarMenuButton>

        <AppBarMenuButton onClick={onRoadmapClick}>
          <AppBarTypography>{MenuAppBarItems.ROADMAP}</AppBarTypography>
        </AppBarMenuButton>
      </ContainerSizeMenuRight>
    </AppBarMenuWrapper>
  )
}
