import React, { useState, useEffect, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'
import { fetchTournament } from '../../services/backend'
import {
  CommonTranslations,
  SnackbarTranslations,
  Translations,
} from '../../config/i18n/i18n'
import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
import { Tournament } from './models/Tournament'
import {
  ContentContainer,
  PrizeContainer,
  TypographyPrize,
} from './Prize.style'
import { TournamentPrizes } from '../../commons/components/TournamentPrizes/TournamentPrizes'

export function Prize() {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])
  const [previousTournament, setPreviousTournament] = useState<Tournament>()
  const [activeTournament, setActiveTournament] = useState<Tournament>()

  const getActiveTournament = useCallback(async () => {
    try {
      const data = await fetchTournament({ limit: 1, active: true })
      setActiveTournament(data.results[0])
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_TOURNAMENT), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, t])

  const getPreviousTournament = useCallback(async () => {
    try {
      const data = await fetchTournament({
        limit: 1,
        active: false,
        ordering: 'created_at',
      })
      setPreviousTournament(data.results[0])
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_TOURNAMENT), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, t])

  useEffect(() => {
    getActiveTournament()
    getPreviousTournament()
  }, [getActiveTournament, getPreviousTournament])
  return (
    <PrizeContainer id="prize-section" data-testid="prize:page-container">
      <DashboardAppBar />
      <ContentContainer data-testid="prize:content-container">
        <TypographyPrize data-testid="prize:page-title">
          {t(CommonTranslations.PRIZES)}
        </TypographyPrize>
        <Divider sx={{ width: '100%' }} />
        <TypographyPrize data-testid="prize:active-tournament">
          {t(CommonTranslations.ACTIVE_TOURNAMENT)}
        </TypographyPrize>
        {activeTournament && <TournamentPrizes tournament={activeTournament} />}
        <Divider sx={{ width: '100%' }} />
        <TypographyPrize data-testid="prize:previous-tournament">
          {t(CommonTranslations.PREVIOUS_TOURNAMENT)}
        </TypographyPrize>
        {previousTournament && (
          <TournamentPrizes tournament={previousTournament} />
        )}
      </ContentContainer>
    </PrizeContainer>
  )
}
