import { styled } from '@mui/material'
import { GridContainer } from '../../../../commons/Custom.style'
import { TypographyDescription } from '../../Home.style'

export const GnomesSectionContainer = styled(GridContainer)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'minmax(300px, 50%) minmax(600px, 50%)',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: 'unset',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const GnomesTypesContainer = styled(GridContainer)(({ theme }) => ({
  gridAutoFlow: 'column',
  gap: '3rem',
  marginTop: 20,
  [theme.breakpoints.down('md')]: {
    gridAutoFlow: 'row',
  },
}))

export const TypographyDetail = styled(TypographyDescription)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '22px',
  lineHeight: '26.4px',
  maxWidth: '645px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}))

export const DescriptionContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  gap: '40px',
}))

export const CharactersButtonsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, auto)',
  gridAutoRows: 'auto',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(3, auto)',
    gap: '20px',
  },
}))

export const GnomePreviewContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  maxHeight: '602px',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
