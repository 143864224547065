import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { commons } from '../../../../images'
import { CommonTranslations, Translations } from '../../../../config/i18n/i18n'
import { useWalletContext } from '../../../../commons/contexts/WalletContext'
import { TOKEN_SYMBOL } from '../../../../config'
import { CommonButton } from '../../../../commons/components/CommonButton/CommonButton'

import {
  ChestCardContainer,
  ChestCardTitle,
  ChestImg,
  ChestImgWrapper,
  ChestInfoWrapper,
  ErrorFeedback,
  InfoTag,
  InfoTagContent,
  InfoTagLabel,
  InfoTokenSymbol,
  TagTextGray,
} from './ChestCard.style'

export function ChestCard() {
  const { t } = useTranslation([Translations.COMMON])
  const { chestPrice, getChestPrice, buyChest } = useWalletContext()

  const [loading, setLoading] = useState<boolean>(false)
  const GNOMES_QTY = 1
  const errorRequest = false

  const buy = useCallback(async () => {
    setLoading(true)
    await buyChest()
    setLoading(false)
  }, [buyChest])

  useEffect(() => {
    getChestPrice()
  }, [getChestPrice])

  return (
    <ChestCardContainer>
      <ChestCardTitle>{t(CommonTranslations.CHEST)}</ChestCardTitle>

      <ChestImgWrapper>
        <ChestImg src={commons.chest} />
      </ChestImgWrapper>

      <ChestInfoWrapper>
        <InfoTag>
          <InfoTagLabel>{t(CommonTranslations.INCLUDES)}</InfoTagLabel>

          <InfoTagContent>
            <TagTextGray>{`${GNOMES_QTY} ${t(
              CommonTranslations.RANDOM_GNOME,
            )}`}</TagTextGray>
          </InfoTagContent>
        </InfoTag>

        <InfoTag>
          <InfoTagLabel>{t(CommonTranslations.PRICE)}</InfoTagLabel>

          <InfoTagContent>
            <InfoTokenSymbol src={commons.lacSymbol} />
            <InfoTagLabel>
              {chestPrice} {TOKEN_SYMBOL}
            </InfoTagLabel>
            <TagTextGray>$1.42</TagTextGray>
          </InfoTagContent>
        </InfoTag>
      </ChestInfoWrapper>

      {loading && <CircularProgress size={24} />}

      {!loading && (
        <CommonButton
          text={t(CommonTranslations.BUY)}
          clickHandler={buy}
          disabled={loading}
          full
        />
      )}

      {errorRequest && (
        <ErrorFeedback>{t(CommonTranslations.REQUEST_ERROR)}</ErrorFeedback>
      )}
    </ChestCardContainer>
  )
}
