import React, { useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import {
  CarouselContainer,
  CategoryContainer,
  CompetitionContainer,
  ContentContainer,
  DashedImg,
  GridImageContainer,
  TypographyColumnContainer,
  TypographyCompetition,
  TypographyDetail,
} from './Competition.style'
import {
  RankingCategory,
  categories,
} from '../../../../commons/components/RankingCategory'
import { Carousel } from '../../../../commons/components/Carousel'

export function CompetitionDescriptionSection() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const categoryTypesText = useMemo(
    () =>
      [...categories]
        .reverse()
        .join(', ')
        // this replace the lastest "," with "o"
        .replace(/, ([^,]*)$/, ' o $1'),
    [],
  )
  return (
    <CompetitionContainer
      id="competition-section"
      data-testid="competition:container"
    >
      <ContentContainer data-testid="competition:content-container">
        <TypographyColumnContainer data-testid="competition:typography-column-container">
          <TypographyCompetition>Lucha junto a tu equipo</TypographyCompetition>
          <TypographyDetail>
            Compite en los distintos rankings: {categoryTypesText}. Si logras
            vencer a tus rivales puedes ganar premios!
          </TypographyDetail>
        </TypographyColumnContainer>
        {isMobile ? (
          <CarouselContainer data-testid="competition:carousel-container">
            <Carousel>
              {categories.map(category => (
                <CategoryContainer
                  key={`mobile:${category}`}
                  data-testid="competition:category-container"
                >
                  <RankingCategory category={category} />
                </CategoryContainer>
              ))}
            </Carousel>
          </CarouselContainer>
        ) : (
          <GridImageContainer data-testid="competition:grid-image-container">
            {categories.map(category => (
              <RankingCategory key={`web:${category}`} category={category} />
            ))}
          </GridImageContainer>
        )}
      </ContentContainer>
      <DashedImg />
    </CompetitionContainer>
  )
}
