import { styled } from '@mui/material'
import {
  TeamSectionContainer,
  TeamSectionTitleContainer,
} from '../Team/Team.style'
import { background } from '../../../../images'
import { Cloud } from '../Roadmap/Roadmap.style'

export const PartnersSectionContainer = styled(TeamSectionContainer)(
  ({ theme }) => ({
    background: `url(${background.forest})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    height: '1500px',
    alignItems: 'flex-start',
    gridTemplateRows: '200px auto',
    transform: 'translateY(250px)',
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(100px)',
    },
  }),
)

export const CloudPartners = styled(Cloud)({
  transform: 'translateX(200px) translateY(-40px)',
})

export const PartnersCarouselContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
})

export const PartnersSectionTitleContainer = styled(TeamSectionTitleContainer)({
  height: '100px',
})
