import { Grid, css, styled } from '@mui/material'

export const ChestWrapper = styled(Grid)(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
  `,
)

export const ChestContainer = styled('div')(() => ({
  width: '100%',
  overflowX: 'hidden',
  padding: '0px 50px 50px 50px',
  boxSizing: 'border-box',
}))

export const ContentContainer = styled(Grid)(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin: 0 auto;
  `,
)
