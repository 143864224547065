import React from 'react'
import { AddressContainer, Center, Left, Right } from './AddressShorten.styles'

interface AddressShortenProps {
  address: string | number
  className?: string
  chartQty?: number
  autoShorten?: boolean
}

export function AddressShorten({
  address,
  className,
  chartQty = 8,
  autoShorten,
}: AddressShortenProps) {
  const finalAddress = address?.toString()
  if (autoShorten) {
    return (
      <AddressContainer className={className}>
        {finalAddress?.length > 10 ? (
          <>
            <Left>{finalAddress?.slice(0, chartQty / 2)}</Left>
            <Center>...</Center>
            <Right>{finalAddress?.slice(-chartQty / 2)}</Right>
          </>
        ) : (
          <Center>{finalAddress}</Center>
        )}
      </AddressContainer>
    )
  }

  return (
    <AddressContainer>
      <Left>{finalAddress?.slice(0, chartQty / 2)}</Left>
      <Center>...</Center>
      <Right>{finalAddress?.slice(-chartQty / 2)}</Right>
    </AddressContainer>
  )
}
