import { css, styled } from '@mui/material'

export const PriceToken = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.main};
    font-size: 18px;
    font-weight: 600;
    line-height: initial;
    white-space: nowrap;
  `,
)

export const InfoTokenSymbol = styled('img')(
  () => css`
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    position: relative;
    top: -1px;
  `,
)

export const PriceWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
)

export const HeaderPriceInfo = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
  `,
)

export const HeaderPriceWrapper = styled('div')(
  () => css`
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 1.5rem;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 47.23%
    );
  `,
)

export const GnomeSkill = styled('div')(
  ({ theme }) => css`
    width: fit-content;
    height: 24px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.font.neutral01};
    padding: 0 8px;
    background: ${theme.palette.font.main};
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    color: ${theme.palette.font.neutral50};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
  `,
)

export const GnomeSkillsWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 6px;
  `,
)

export const GnomeId = styled('div')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.neutral60};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

export const BodyInfoWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
  `,
)

export const CardBodyWrapper = styled('div')(
  () => css`
    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 0 0 16px 16px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    box-sizing: border-box;
  `,
)

export const HeaderOptionsTeam = styled('div')(
  () => css`
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.75);
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    box-sizing: border-box;

    &:hover {
      opacity: 1;
    }
  `,
)

export const CardHeaderImage = styled('img')(
  () => css`
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
  `,
)

export const CardHeaderWrapper = styled('div')(
  () => css`
    width: 100%;
    height: 168px;
    position: relative;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  `,
)

export const GnomeCardNewContainer = styled('div')(
  ({ theme }) => css`
    width: 272px;
    max-height: 336px;
    padding-bottom: 16px;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid ${theme.palette.font.neutral10};
    background: ${theme.palette.font.main};
    box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.1);
  `,
)
