import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { useWalletContext } from '../../contexts/WalletContext'
import { CommonTranslations, Translations } from '../../../config/i18n/i18n'
import { Gnome } from '../Gnome'

interface GnomeMarketProps {
  gnomeId: string
  marketplaceId: string
  seller: string
  price: string
}

export const GnomeMarket = React.memo(
  ({ gnomeId, marketplaceId, seller, price }: GnomeMarketProps) => {
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation([Translations.COMMON])
    const { address, buyMarketplaceGnome, removeItemFromMarketplace } =
      useWalletContext()

    const handleOnClickBuy = useCallback(async () => {
      setLoading(true)
      await buyMarketplaceGnome(marketplaceId, price)
      setLoading(false)
    }, [buyMarketplaceGnome, marketplaceId, price])

    const actionsComponents = useMemo(() => {
      if (seller === address) {
        return (
          <Button
            size="small"
            onClick={() => removeItemFromMarketplace(marketplaceId)}
          >
            {t(CommonTranslations.CANCEL)}
          </Button>
        )
      }

      return (
        <Button onClick={handleOnClickBuy} size="small">
          {t(CommonTranslations.BUY)}
        </Button>
      )
    }, [
      address,
      seller,
      t,
      handleOnClickBuy,
      marketplaceId,
      removeItemFromMarketplace,
    ])

    return (
      <Gnome
        gnomeId={gnomeId}
        actionsComponents={actionsComponents}
        loading={loading}
      />
    )
  },
)
