import axios from 'axios'
import { API_BACKEND_URL } from '../../config'

export const backend = axios.create({
  baseURL: API_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})
