import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import commonEn from '../../languages/en/common.json'
import commonEs from '../../languages/es/common.json'
import snackbarEs from '../../languages/es/snackbar.json'
import snackbarEn from '../../languages/en/snackbar.json'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    resources: {
      en: {
        common: commonEn,
        snackbar: snackbarEn,
      },
      es: {
        common: commonEs,
        snackbar: snackbarEs,
      },
    },
  })
