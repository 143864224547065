import { css, styled } from '@mui/material'

interface SelectSortProps {
  active?: boolean
  disabled?: boolean
}

export const OptionIcon = styled('img')(
  () => css`
    width: 18px;
    height: auto;
    flex: 0 0 auto;
    position: relative;
    z-index: 4;
  `,
)

export const ItemText = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral50};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

export const ItemAction = styled('button')<SelectSortProps>(
  ({ theme, active }) => css`
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;

    &:hover {
      background-color: ${theme.palette.font.neutral10};
      cursor: pointer;
    }

    ${active &&
    css`
      background-color: ${theme.palette.font.neutral10};
      pointer-events: none;
      &:hover {
        cursor: default;
      }
    `}
  `,
)

export const OptionItem = styled('li')(
  ({ theme }) => css`
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.palette.font.neutral01};
    display: flex;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    &:last-of-type {
      border-bottom: none;
    }
  `,
)

export const OptionList = styled('ul')(
  ({ theme }) => css`
    width: 100%;
    list-style: none;
    border-radius: 8px;
    border: 1px solid ${theme.palette.font.neutral01};
    background: ${theme.palette.font.main};
    color: ${theme.palette.font.neutral50};
    position: absolute;
    box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.1);
    border-radius: 8px;
    z-index: 2;
    overflow: hidden;
    left: 0;
    top: 40px;
    margin-top: 0.5rem;
  `,
)

export const ChevronIcon = styled('img')<SelectSortProps>(
  ({ active }) => css`
    width: 18px;
    height: auto;
    flex: 0 0 auto;
    margin-left: auto;
    position: relative;
    z-index: 4;

    ${active &&
    css`
      transform: rotate(180deg);
    `}
  `,
)

export const SelectedItemText = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral50};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

export const SelectedItemWrapper = styled('button')(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
    padding: 8px 12px;
    border-radius: 8px;
    position: relative;
    z-index: 1;

    &:hover {
      background-color: ${theme.palette.font.neutral10};
      cursor: pointer;
    }
  `,
)

export const SelectStyled = styled('div')(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid ${theme.palette.font.neutral01};
    background: ${theme.palette.font.main};
    color: ${theme.palette.font.neutral50};
    display: flex;
    align-items: center;
    box-sizing: border-box;
  `,
)

export const SelectSortContainer = styled('div')<SelectSortProps>(
  ({ disabled }) => css`
    width: 100%;
    max-width: 230px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;

    ${disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
  `,
)
