import React from 'react'
import { ResponsiveCarousel } from '../ResponsiveCarousel'
import { TeamWorkerCard } from '../TeamWorkerCard'
import { partners } from '../../constants'

export function PartnersCarousel() {
  return (
    <ResponsiveCarousel>
      {partners.map(({ name, image }, i) => (
        <TeamWorkerCard key={i} name={name} img={image} />
      ))}
    </ResponsiveCarousel>
  )
}
