import { Grid, styled } from '@mui/material'
import { GameTypography } from '../../commons/Custom.style'

export const PrizeContainer = styled('div')(() => ({
  width: '100%',
  overflowX: 'hidden',
  padding: '0px 50px 50px 50px',
  boxSizing: 'border-box',
}))

export const ContentContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
}))

export const TypographyColumnContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '10px',
})

export const TypographyPrize = styled(GameTypography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '50px',
  lineHeight: '60px',
  color: theme.palette.font.main,
  fontFamily: 'Gameria',
  maxWidth: '340px',
  [theme.breakpoints.down('md')]: {
    fontSize: '40px',
    lineHeight: '40px',
  },
}))

export const CarouselContainer = styled('div')(() => ({
  display: 'flex',
  placeItems: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  width: '301px',
  justifyContent: 'center',
}))
