import React from 'react'
import { ButtonValues } from '../../types'

import {
  ButtonContainer,
  IconButton,
  IconButtonSmall,
  LinkTo,
} from './CommonButton.styles'

interface ButtonProps {
  text: string
  icon?: string
  iconSmall?: string
  clickHandler?: () => void
  disabled?: boolean
  full?: boolean
  small?: boolean
  variant?: ButtonValues
  to?: string
}

export function CommonButton({
  text,
  clickHandler,
  disabled,
  full,
  variant,
  icon,
  iconSmall,
  small,
  to,
}: ButtonProps) {
  return (
    <ButtonContainer
      onClick={clickHandler}
      disabled={disabled}
      style={{ width: full ? '100%' : 'auto' }}
      variant={variant ?? ButtonValues.Primary}
      small={small}
    >
      {to && <LinkTo to={to} />}
      {text}

      {icon && <IconButton src={`${icon}`} />}
      {iconSmall && <IconButtonSmall src={`${iconSmall}`} />}
    </ButtonContainer>
  )
}
