import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../../../config'
import { GnomeType } from '../../../../../../commons/types'

interface GetGnomeTypeProps {
  gnomeType: GnomeType | undefined
}

export function GetGnomeType({ gnomeType }: GetGnomeTypeProps) {
  const { t } = useTranslation([Translations.COMMON])

  if (!gnomeType) return `${t(CommonTranslations.GNOME_TYPE_UNKNOWN)}`

  if (gnomeType === GnomeType.Rare)
    return `${t(CommonTranslations.GNOME_TYPE_RARE)}`

  if (gnomeType === GnomeType.Epic)
    return `${t(CommonTranslations.GNOME_TYPE_EPIC)}`

  if (gnomeType === GnomeType.Infrequent)
    return `${t(CommonTranslations.GNOME_TYPE_INFREQUENT)}`
}
