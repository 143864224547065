import { css, styled } from '@mui/material'

export const BalanceWrapper = styled('span')(
  ({ theme }) => css`
    width: 100%;
    text-transform: uppercase;
    font-size: 2.2rem;
    color: ${theme.palette.font.neutral60};
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1rem 1.5rem;
  `,
)

export const HeaderPopIcon = styled('img')({
  width: '20px',
  height: 'auto',
  flex: '0 0 auto',
})

export const Capitalize = styled('span')(
  ({ theme }) => css`
    text-transform: capitalize;
    font-size: 14px;
    color: ${theme.palette.font.neutral40};
    font-weight: 500;
  `,
)

export const HeaderPopTitle = styled('div')(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.font.neutral40};
    font-weight: 500;
  `,
)

export const HeaderAccountPop = styled('div')(
  ({ theme }) => css`
    width: 100%;
    padding: 16px 16px 8px 16px;
    border-bottom: 1px solid ${theme.palette.font.neutral10};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
)

export const AddressAvatar = styled('img')(
  () => css`
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 100%;
    flex: 0 0 auto;
  `,
)

export const Address = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  `,
)

export const CopyExplorerWrapper = styled('div')(
  () => css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  `,
)

export const AddressAvatarWrapper = styled('div')(
  () => css`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
)

export const AddressWapper = styled('div')(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    gap: 2rem;
    box-sizing: border-box;
    border-top: 1px solid ${theme.palette.font.neutral10};
  `,
)

export const ButtonWapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 12px 12px;
    padding: 1rem 2rem;
    box-sizing: border-box;

    button {
      min-width: 240px;
    }
  `,
)

export const WalletDisconnectContainer = styled('div')(
  ({ theme }) => css`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 1rem;
    transition: all 0.3s ease-in-out;
    background-color: red;
    border: 1px solid ${theme.palette.font.neutral10};
    background-color: ${theme.palette.font.main};
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 32px;
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 100;
  `,
)
