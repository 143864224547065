import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import {
  ContainerForm,
  LoginButton,
  LoginButtonTypography,
  LoginLayout,
  LogoImage,
} from './styles/Login'
import { useAppContext } from '../../commons/contexts/AppContext'
import { useWalletContext } from '../../commons/contexts/WalletContext'
import { logo } from '../../images'
import { SnackbarTranslations, Translations } from '../../config/i18n/i18n'
import { RoutesEnum } from '../../routes/constants'

export function Login() {
  const [loading, setLoading] = useState<boolean>(false)

  const { session, getSession } = useAppContext()
  const { address, browserProvider } = useWalletContext()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.SNACKBAR])

  useEffect(() => {
    if (session) {
      navigate(RoutesEnum.PLAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  const onLogin = async () => {
    try {
      if (!address || !browserProvider) {
        enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_CONNECT_TO_WALLET), {
          variant: 'error',
        })
        return
      }
      setLoading(true)
      await getSession(address, browserProvider)
      // there is no need for catch and enqueue, because getSession already do it.
    } finally {
      setLoading(false)
    }
  }
  return (
    <LoginLayout data-testid="login:layout">
      <ContainerForm data-testid="login:container-form">
        <LogoImage src={logo.blastedGnomes} data-testid="login:img-logo" />
        <LoginButton
          disabled={loading}
          onClick={onLogin}
          data-testid="login:login-button"
        >
          <LoginButtonTypography loading={loading} data-testid="login:button">
            {loading ? 'Cargando...' : 'Conectar'}
          </LoginButtonTypography>
        </LoginButton>
      </ContainerForm>
    </LoginLayout>
  )
}
