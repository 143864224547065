export const RoutesEnum = {
  LOGIN: '/login',
  BASE: '/',
  PLAY: '/play',
  CHEST: '/chest',
  TEAM: '/gnomes',
  INVENTORY: '/inventory',
  MARKETPLACE: '/marketplace',
  PRIZES: '/prizes',
}
