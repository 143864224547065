import React, { useState } from 'react'
import { commons } from '../../../images'
import { AddressShorten } from '../AddressShorten/AddressShorten'
import { useWalletContext } from '../../contexts/WalletContext'

import { WalletDisconnect } from '../WalletDisconnect/WalletDisconnect'
import {
  AddressAvatar,
  WalletButtonContainer,
  WalletButtonWrapper,
} from './WalletButton.style'

export function WalletButton() {
  const { address } = useWalletContext()
  const [showDisconnect, setShowDisconnect] = useState<boolean>(false)
  const handleModal = () => setShowDisconnect(!showDisconnect)

  return (
    <WalletButtonContainer>
      <WalletButtonWrapper onClick={handleModal}>
        <AddressAvatar src={commons.accountAvatar} />
        <AddressShorten address={address ?? ''} />
      </WalletButtonWrapper>

      <WalletDisconnect shown={showDisconnect} address={address ?? ''} />
    </WalletButtonContainer>
  )
}
