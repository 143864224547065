import React from 'react'
import {
  FooterContainer,
  FooterContent,
  FooterLogoBlasted,
  FooterLogoContainer,
  LoremName,
  TitleAndButtonsContainer,
  TransparentButton,
  TransparentButtonContainer,
  FooterFinePrintLogoContainer,
  FinePrint,
  LogoFooterRipio,
  FooterDivider,
  CustomContainer,
} from './Footer.style'
import { useAnalitycsEvents } from '../../hooks/useAnalitycsEvents'
import { logo } from '../../../images'
import { GameButton } from '../GameButton'

export function Footer() {
  const { trackGameplay, trackGnomes, trackRoadmap, trackWebInit } =
    useAnalitycsEvents()

  const handleInitClick = () => {
    trackWebInit()
    document
      .getElementById('main-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleGameplayClick = () => {
    trackGameplay()
    document
      .getElementById('competition-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleGnomesClick = () => {
    trackGnomes()
    document
      .getElementById('gnomes-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleRoadmapClick = () => {
    trackRoadmap()
    document
      .getElementById('roadmap-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <FooterContainer data-testid="footer:container">
      <FooterContent data-testid="footer:content">
        <FooterLogoContainer data-testid="footer:logo-container">
          <FooterLogoBlasted
            src={logo.blastedGnomes}
            data-testid="footer:logo-blasted"
          />
          <TitleAndButtonsContainer data-testid="footer:title-and-buttons-container">
            <CustomContainer data-testid="footer:custom-container">
              <LoremName data-testid="footer:lorem-name">
                Lorem ipsum dolor sit
              </LoremName>
              <GameButton onClick={handleRoadmapClick}>Roadmap</GameButton>
            </CustomContainer>
            <TransparentButtonContainer data-testid="footer:transparent-button-container">
              <TransparentButton
                data-testid="footer:transparent-button"
                onClick={handleInitClick}
              >
                Inicio
              </TransparentButton>
              <TransparentButton
                data-testid="footer:transparent-button"
                onClick={handleGameplayClick}
              >
                Gameplay
              </TransparentButton>
              <TransparentButton
                data-testid="footer:transparent-button"
                onClick={handleGnomesClick}
              >
                Gnomes
              </TransparentButton>
            </TransparentButtonContainer>
          </TitleAndButtonsContainer>
        </FooterLogoContainer>
        <FooterDivider data-testid="footer:divider" />
        <FooterFinePrintLogoContainer data-testid="footer:fine-print-logo-container">
          <FinePrint data-testid="footer:fine-print">
            Moonbird S.R.L., que administra la plataforma ripio.com/ar y la
            aplicación móvil &quot;Ripio&quot;, ofrece servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el Banco
            Central de la República Argentina. Los Fondos depositados en cuentas
            de pago no constituyen depósitos en una entidad financiera, ni
            cuentan con ninguna de las garantías que tales depósitos puedan
            gozar de acuerdo con las normas aplicables en materia de depósitos
            en entidades financieras. Los activos digitales pueden tener una
            cotización altamente volátil. Moonbird S.R.L. no efectúa ningún tipo
            de representación o garantía sobre la posibilidad o conveniencia de
            utilizarlos como inversión.
          </FinePrint>
          <LogoFooterRipio
            data-testid="footer:logo-ripio"
            src={logo.mini_ripio}
            alt="ripio"
          />
        </FooterFinePrintLogoContainer>
      </FooterContent>
    </FooterContainer>
  )
}
