import { commons, logo } from '../../images'

export const partners = [
  { name: 'Digi', image: logo.digi },
  { name: 'Ripio', image: logo.ripio },
]

export const team = [
  { name: 'Ariel Nader', position: 'position ', image: commons.teamMember },
  { name: 'Ariel Nader', position: 'position ', image: commons.teamMember },
  { name: 'Ariel Nader', position: 'position ', image: commons.teamMember },
  { name: 'Ariel Nader', position: 'position ', image: commons.teamMember },
]
