import { lambda } from './axios'

export const noncePath = '/nonce'
export const refreshPath = '/refresh'
export const signPath = '/sign'

export interface NonceParameters {
  address: string
}
export interface NonceResponse {
  nonce: string
}
export interface RefreshParameters {
  refresh_token: string
}
export interface SignParameters {
  address: string
  sign: string
}
export interface TokenResponse {
  token: string
  refresh_token: string
}

export async function sign(body: SignParameters): Promise<TokenResponse> {
  const { data } = await lambda.post<TokenResponse>(signPath, body)
  return data
}

export async function nonce(body: NonceParameters): Promise<NonceResponse> {
  const { data } = await lambda.post<NonceResponse>(noncePath, body)
  return data
}

export async function refresh(body: RefreshParameters): Promise<TokenResponse> {
  const { data } = await lambda.post<TokenResponse>(refreshPath, body)
  return data
}
