import { css, styled } from '@mui/material'
import { TagValues } from '../../../../types'

interface UpLevelTagProps {
  variant?: TagValues
}

export const UpLevelIcon = styled('img')(
  () => css`
    width: 14px;
    height: auto;
    flex: 0 0 auto;
    position: relative;
    top: 1px;
  `,
)

export const UpLevelText = styled('span')(
  () => css`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  `,
)

export const UpLevelContainer = styled('div')<UpLevelTagProps>(
  ({ theme, variant }) => css`
    width: fit-content;
    height: 24px;
    border-radius: 8px;
    padding: 0 8px;
    background: ${theme.palette.font.pinkLight};
    color: ${theme.palette.font.pink};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    border: none;

    ${variant === TagValues.InfoLevelUp &&
    css`
      background: ${theme.palette.font.blueLight};
      color: ${theme.palette.font.blue};
    `}
  `,
)
