import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, TOKEN_SYMBOL, Translations } from '../../config'

import { commons, icons } from '../../images'
import { Gnome } from '../../commons/types'
import { GnomeId } from '../../commons/components/GnomeCardNew/GnomeCardNew.style'
import { CommonButton } from '../../commons/components/CommonButton/CommonButton'
import { GnomeTag } from '../../commons/components/GnomeTag/GnomeTag'
import { AddressShorten } from '../../commons/components/AddressShorten/AddressShorten'
import { UpLevelTag } from '../../commons/components/GnomeCardNew/Components/UpLevelTag'

import {
  AddressShortenWapper,
  ArrowIcon,
  BackButtonWrapper,
  DetailLeft,
  DetailRight,
  GnomeDetailsContainer,
  GnomeDetailsWrapper,
  GnomeImage,
  GnomeInfoWrapper,
  InfoTagContent,
  InfoTagLabel,
  InfoTokenSymbol,
  OwnerTitle,
  OwnerWrapper,
  PriceWrapper,
  TagExp,
  TagsWrapper,
} from './GnomeDetails.style'

interface GnomeDetailsProps {
  gnome: Gnome | null
  backAction: () => void
  mainButtonAction: () => void
  tsx: 'sell' | 'buy'
}

export function GnomeDetails({
  gnome,
  backAction,
  mainButtonAction,
  tsx,
}: GnomeDetailsProps) {
  const { t } = useTranslation([Translations.COMMON])

  if (!gnome) return null

  return (
    <GnomeDetailsContainer>
      <BackButtonWrapper onClick={backAction}>
        <ArrowIcon src={icons.leftArrow} />
      </BackButtonWrapper>

      <GnomeDetailsWrapper>
        <DetailLeft>
          <GnomeImage src={gnome.metadata.image} />
        </DetailLeft>

        <DetailRight>
          <GnomeInfoWrapper>
            <GnomeId>{`#${gnome.id}`}</GnomeId>

            <TagsWrapper>
              <GnomeTag gnomeType={gnome.metadata.gnome_type} />

              {gnome.metadata.attributes?.map(att => (
                <TagExp
                  key={att.trait_type}
                >{`${att.trait_type} ${att.value}`}</TagExp>
              ))}

              {gnome.can_level_up && <UpLevelTag />}
            </TagsWrapper>

            <OwnerWrapper>
              <OwnerTitle>{t(CommonTranslations.OWNER)}</OwnerTitle>

              <AddressShortenWapper>
                <AddressShorten address={gnome.owner} />
              </AddressShortenWapper>
            </OwnerWrapper>

            <PriceWrapper>
              <OwnerTitle>{t(CommonTranslations.CURRENT_PRICE)}</OwnerTitle>

              <InfoTagContent>
                <InfoTokenSymbol src={commons.lacSymbol} />
                <InfoTagLabel>{`500 ${TOKEN_SYMBOL}`}</InfoTagLabel>
              </InfoTagContent>
            </PriceWrapper>

            <CommonButton
              text={
                tsx === 'buy'
                  ? t(CommonTranslations.BUY)
                  : t(CommonTranslations.SELL)
              }
              clickHandler={mainButtonAction}
              full
            />
          </GnomeInfoWrapper>
        </DetailRight>
      </GnomeDetailsWrapper>
    </GnomeDetailsContainer>
  )
}
