/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react'
import axios from 'axios'
import { useAppContext } from '../../commons/contexts/AppContext'
import { refresh } from '../lambda'
import { backend } from './axios'

export function AxiosErrorHandler({ children }: { children: JSX.Element }) {
  const {
    refreshSession,
    removeSession,
    removeRefreshSession,
    setTokenCookie,
    setRefreshTokenCookie,
  } = useAppContext()

  useEffect(() => {
    const responseInterceptor = backend.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config
        if (error.response.status === 403) {
          if (refreshSession) {
            return refresh({ refresh_token: refreshSession })
              .then(res => {
                setTokenCookie(res.token)
                setRefreshTokenCookie(res.refresh_token)
                originalRequest.headers.Authorization = res.token
                return axios(originalRequest).catch(err => {
                  // the original request could fail, if the refresh was successful but the original
                  // request fails, then add a custom flag in order to not remove the session in the
                  // upper catch as the reason for the failure wasn't a token related issue.
                  // eslint-disable-next-line no-param-reassign
                  err._failed = true
                  throw err
                })
              })
              .catch(err => {
                if (!err._failed) {
                  removeSession()
                  removeRefreshSession()
                }
                throw err
              })
          }
          removeSession()
          removeRefreshSession()
          throw error
        }
        throw error
      },
    )

    return () => {
      backend.interceptors.response.eject(responseInterceptor)
    }
  }, [
    refreshSession,
    removeRefreshSession,
    removeSession,
    setTokenCookie,
    setRefreshTokenCookie,
  ])

  return children
}
