import { styled, css } from '@mui/material'

export const GameIframe = styled('iframe')(
  () => css`
    width: 100%;
    max-width: 1100px;
    height: auto;
    aspect-ratio: 16/9;
  `,
)

export const GameContainer = styled('div')(
  () => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: black;
  `,
)
