import { Button, styled } from '@mui/material'
import { GameTypography } from '../../Custom.style'
import { background } from '../../../images'

export const ButtonContainer = styled(Button)(({ theme }) => ({
  boxSizing: 'border-box',
  height: '55px',
  width: '175px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8.34px 27.82px',
  gap: '5.56px',
  isolation: 'isolate',
  background: theme.palette?.button?.main,
  boxShadow:
    '0px 8.34624px 3.4776px rgba(0, 0, 0, 0.04), 0px 4.86864px 2.78208px rgba(0, 0, 0, 0.12), 0px 2.08656px 2.08656px rgba(0, 0, 0, 0.2), 0px 0.69552px 1.39104px rgba(0, 0, 0, 0.24), 0px 0px 0px rgba(0, 0, 0, 0.24)',
  borderRadius: '16px',
  transition: 'width .1s ease-out, height .1s ease-out',
  ':active': {
    boxShadow:
      'inset 0 0 10px 2px rgba(0, 0, 0, 0.15), 1px 1px 15px 2px rgba(154, 137, 164, 0.1)',
    textShadow:
      '1px -1px 1px rgba(143, 162, 199, 0.75), -1px 1px 1px rgba(192, 151, 178, 0.75)',
    height: '65px',
    width: '185px',
  },
}))
export const ButtonShadowContainer = styled('div')({
  position: 'absolute',
  height: '14.61px',
  left: '5.56px',
  right: '5.6px',
  bottom: '1px',
  flex: 'none',
  order: 0,
  flexGrow: 0,
  zIndex: 0,
  background: `url(${background.buttonShadow})`,
  backgroundSize: 'contain',
})

export const ButtonLightLeft = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '5.56px',
  height: '6.96px',
  left: '8.37px',
  top: '6.73px',
  background: theme.palette.button?.secondary,
  transform: 'rotate(30deg)',
  flex: 'none',
  order: 3,
  flexGrow: 0,
  zIndex: 3,
  borderRadius: '50%',
}))
export const ButtonLightRight = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '5.56px',
  height: '11.13px',
  right: '11.17px',
  top: '8.11px',
  background: theme.palette.button?.secondary,
  transform: 'rotate(-45deg)',
  flex: 'none',
  order: 2,
  flexGrow: 0,
  zIndex: 2,
  borderRadius: '50%',
}))
export const ButtonTypography = styled(GameTypography)({
  width: '113px',
  height: '28px',
  fontFamily: 'Gameria',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '22.25px',
  lineHeight: '28px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  letterSpacing: '1.39px',
  color: '#FFFFFF',
  textShadow:
    '0px 1.39px 0px rgba(79, 79, 79, 0.6), 0px 1.39px 5.56px rgba(0, 0, 0, 0.25)',
  flex: 'none',
  order: 1,
  flexGrow: 0,
})
