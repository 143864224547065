import React from 'react'
import {
  CharacterButtonContainer,
  ImageContainer,
  ImgGnome,
} from './CharacterButton.style'
import { commons } from '../../../images'

// A default preview will be used as there aren't new gnomes images
export function CharacterButton() {
  return (
    <CharacterButtonContainer data-testid="character-button:character-button-container">
      <ImageContainer data-testid="character-button:image-container">
        <ImgGnome
          data-testid="character-button:image"
          src={commons.gnome}
          alt="gnomes_characters"
        />
      </ImageContainer>
    </CharacterButtonContainer>
  )
}
