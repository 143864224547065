import React from 'react'
import { Grid, Pagination, Typography } from '@mui/material'
import { MarketplaceGnome } from '../../../pages/Marketplace/models/MarketplaceGnome'
import { GnomeContainer } from './GnomeContainer'
import { GnomeMarket } from '../GnomeMarket/GnomeMarket'
import { ContentContainer } from '../../../pages/GnomesTeam/GnomesTeam.style'

interface GnomeMarketContainerProps {
  gnomes: MarketplaceGnome[]
  label: string
  count: number
  onRequestMore: (event: React.ChangeEvent<unknown>, page: number) => void
}

export const GnomeMarketContainer = React.memo(
  ({ gnomes, label, count, onRequestMore }: GnomeMarketContainerProps) =>
    gnomes.length ? (
      <ContentContainer data-testid="gnomes-market-container:container">
        <Typography variant="h3" fontFamily="Onacona" color="font.secondary">
          {label}
        </Typography>
        <GnomeContainer data-testid="gnomes-market-container:gnome-container">
          {gnomes.map(gnome => (
            <GnomeMarket
              key={gnome.id}
              {...gnome}
              gnomeId={gnome.gnome.split('/').reverse()[1]}
              marketplaceId={gnome.marketplace_id}
            />
          ))}
        </GnomeContainer>
        {count ? (
          <Grid
            data-testid="gnomes-market-container:grid-pagination"
            container
            justifyContent="center"
            alignItems="center"
          >
            <Pagination
              data-testid="gnomes-market-container:pagination"
              count={count}
              onChange={onRequestMore}
            />
          </Grid>
        ) : null}
      </ContentContainer>
    ) : null,
)
