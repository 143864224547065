export enum TagValues {
  CanLevelUp = 'Up',
  InfoLevelUp = 'Info',
}

export enum ButtonValues {
  Primary = 'primary',
  Secondary = 'secondary',
  White = 'white',
  Square = 'square',
  Transparent = 'transparent',
}

export enum GnomeType {
  Rare = 'rare',
  Epic = 'epic',
  Infrequent = 'infrequent',
}

export interface OptionProps {
  value?: string
  text?: string
  icon?: string
}

export interface Gnome {
  id: number
  owner: string
  can_level_up: boolean
  metadata: Metadata
}

export interface Metadata {
  image: string
  gnome_type: GnomeType
  price_lac?: string
  attributes: AttributesEntity[]
}

export interface AttributesEntity {
  value: string
  trait_type: string
}
