import { css, styled } from '@mui/material'

export const GnomeId = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.main};
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
  `,
)

export const GnomeCardFooter = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-top: auto;
    position: relative;
    z-index: 4;
  `,
)

export const CardImage = styled('img')(
  () => css`
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  `,
)

export const CardShadow = styled('div')(
  () => css`
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 100%
    );
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
  `,
)

export const ModalGnomeCardContainer = styled('div')(
  () => css`
    width: 100%;
    max-width: 200px;
    height: auto;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding: 1.5rem;
  `,
)
