import { css, styled } from '@mui/material'

export const AddressAvatar = styled('img')(
  () => css`
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 100%;
    flex: 0 0 auto;
  `,
)

export const Address = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  `,
)

export const WalletButtonWrapper = styled('button')(
  ({ theme }) => css`
    width: auto;
    min-height: 40px;
    border: 1px solid ${theme.palette.font.neutral10};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
    padding: 1rem 1.5rem;
    border-radius: 12px;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  `,
)

export const WalletButtonContainer = styled('div')(
  () => css`
    width: auto;
    position: relative;
  `,
)
