import React from 'react'
import { TeamImage, TeamWorkerContainer } from './TeamWorkerCard.style'
import {
  TeamWorkerTextContainer,
  TypographyWorker,
} from '../../../pages/Home/components/Team/Team.style'

interface TeamWorkerCardProps {
  name: string
  position?: string
  img: string
}

export function TeamWorkerCard({ name, position, img }: TeamWorkerCardProps) {
  return (
    <TeamWorkerContainer data-testid="team-worker:container">
      <TeamImage data-testid="team-worker:image-worker" src={img} alt={name} />
      <TeamWorkerTextContainer data-testid="team-worker:container">
        <TypographyWorker data-testid="team-worker:typography">
          <strong>{name}</strong>
        </TypographyWorker>
      </TeamWorkerTextContainer>
      {position ? (
        <TeamWorkerTextContainer data-testid="team-worker:container">
          <TypographyWorker data-testid="team-worker:typography">
            <strong>{position}</strong>
          </TypographyWorker>
        </TeamWorkerTextContainer>
      ) : null}
    </TeamWorkerContainer>
  )
}
