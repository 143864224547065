import React, { useCallback } from 'react'
import { parseUnits } from 'ethers'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../config/i18n/i18n'
import { TOKEN_SYMBOL } from '../../../../config'
import { Gnome } from '../../../../commons/types'

import { CommonButton } from '../../../../commons/components/CommonButton/CommonButton'
import { GnomeTag } from '../../../../commons/components/GnomeTag/GnomeTag'
import { useWalletContext } from '../../../../commons/contexts/WalletContext'
import { icons } from '../../../../images'

import {
  FeedbackMsg,
  FeedbackMsgIcon,
  GnomeInfoWrapper,
  ImageGnome,
  ImageId,
  ImageInfo,
  ImageOver,
  ImageWrapper,
  InfoDesc,
  InfoTitle,
  InfoWrapper,
  InputWrapper,
  LabelInput,
  PriceInput,
  SellGnomeModalContainer,
} from './SellGnomeModal.style'

interface SellGnomeModalProps {
  gnome: Gnome | null
  onConfirm: (gnomeId: number | undefined) => void
}

export function SellGnomeModal({ gnome, onConfirm }: SellGnomeModalProps) {
  /* Translate */
  const { t } = useTranslation([Translations.COMMON])

  /* Handler confirm button behavior */
  const [price, setPrice] = React.useState<number | string>(
    `${t(CommonTranslations.PRICE)}`,
  )
  const { listItemToMarketplace } = useWalletContext()

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value)
  }

  const handleInputChange = useCallback(async () => {
    if (!gnome) return

    const weiPrice = parseUnits(price.toString(), 'ether').toString()
    const weiPriceBN = new BigNumber(weiPrice)
    const isValidPrice = !weiPriceBN.isZero()

    if (isValidPrice)
      await listItemToMarketplace(gnome?.id?.toString(), weiPriceBN)

    if (onConfirm) onConfirm(gnome.id)
  }, [gnome, price, onConfirm, listItemToMarketplace])

  if (!gnome) return null

  return (
    <SellGnomeModalContainer>
      <GnomeInfoWrapper>
        <ImageWrapper>
          <ImageOver />
          <ImageGnome src={gnome?.metadata?.image} />
          <ImageInfo>
            <ImageId>{`#${gnome.id}`}</ImageId>

            <GnomeTag gnomeType={gnome.metadata.gnome_type} />
          </ImageInfo>
        </ImageWrapper>

        <InfoWrapper>
          <InfoTitle>{`${t(CommonTranslations.SELL)} Gnomo`}</InfoTitle>
          <InfoDesc>{t(CommonTranslations.SELL_DESC)}</InfoDesc>

          <InputWrapper>
            <LabelInput>
              {`${t(CommonTranslations.TOTAL_IN)} ${TOKEN_SYMBOL}`}
            </LabelInput>

            <PriceInput
              onChange={handleOnChange}
              value={price}
              type="number"
              placeholder={t(CommonTranslations.PRICE) as string}
            />

            <FeedbackMsg>
              <FeedbackMsgIcon src={icons.alertFeedback} />
              Mensaje de ejemplo para feedback
            </FeedbackMsg>
          </InputWrapper>
        </InfoWrapper>
      </GnomeInfoWrapper>

      <CommonButton
        text={t(CommonTranslations.CONFIRM)}
        clickHandler={handleInputChange}
      />
    </SellGnomeModalContainer>
  )
}
