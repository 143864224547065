import React, { memo } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Skeleton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../config/i18n/i18n'

export interface GnomeCardProps {
  tier?: string
  level?: number
  xp?: number
  loading?: boolean
  image?: string
  actionsComponents?: React.ReactNode
}

export const GnomeCard = memo(
  ({ image, tier, level, xp, actionsComponents, loading }: GnomeCardProps) => {
    const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])
    return (
      <Card sx={{ width: 180, minHeight: 250 }} data-testid="gnome:card">
        {image && !loading ? (
          <CardMedia
            data-testid="gnome:card-media"
            sx={{ height: 140 }}
            image={image}
          />
        ) : (
          <Skeleton width="150px" height="140px" data-testid="gnome:skeleton" />
        )}
        <CardContent sx={{ paddingX: 0 }} data-testid="gnome:card-content">
          {tier && !loading ? (
            <Typography gutterBottom>
              {`${t(CommonTranslations.GNOMES_CARD_TIER)}: ${tier}`}
            </Typography>
          ) : (
            <Skeleton
              width="100px"
              height="20px"
              data-testid="gnome:skeleton"
            />
          )}
          {level && !loading ? (
            <Typography variant="body2" color="text.secondary">
              {`${t(CommonTranslations.GNOMES_CARD_LEVEL)}: ${level}`}
            </Typography>
          ) : (
            <Skeleton width="50px" height="20px" data-testid="gnome:skeleton" />
          )}
          {xp != null && !loading ? (
            <Typography variant="body2" color="text.secondary">
              {`${t(CommonTranslations.GNOMES_CARD_XP)}: ${xp}`}
            </Typography>
          ) : (
            <Skeleton width="50px" height="20px" data-testid="gnome:skeleton" />
          )}
        </CardContent>
        {loading ? null : (
          <CardActions sx={{ paddingX: 0 }} data-testid="gnome:card-actions">
            {actionsComponents}
          </CardActions>
        )}
      </Card>
    )
  },
)
