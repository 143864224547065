import React, { useCallback, useEffect, useState } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { MarketplaceLayout } from './Marketplace.style'
import { useWalletContext } from '../../commons/contexts/WalletContext'
import { MarketplaceGnome } from './models/MarketplaceGnome'
import { GnomeMarketContainer } from '../../commons/components/GnomeContainer/GnomeMarketContainer'
import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
import { LIMIT_GNOMES_REQUEST } from '../../config'
import {
  CommonTranslations,
  SnackbarTranslations,
  Translations,
} from '../../config/i18n/i18n'
import { fetchSales } from '../../services/backend'

interface MarketplaceCategory {
  selfGnomes: { gnomes: MarketplaceGnome[]; pages: number }
  sellers: { gnomes: MarketplaceGnome[]; pages: number }
  community: { gnomes: MarketplaceGnome[]; pages: number }
}

export const Marketplace = React.memo(() => {
  const [marketplaceGnomes, setMarketplaceGnomes] =
    useState<MarketplaceCategory>({
      selfGnomes: { gnomes: [], pages: 1 },
      sellers: { gnomes: [], pages: 1 },
      community: { gnomes: [], pages: 1 },
    })
  const { enqueueSnackbar } = useSnackbar()

  const { address } = useWalletContext()

  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])

  const getOwnSales = useCallback(
    async (page: number) => {
      if (!address) return
      try {
        const data = await fetchSales({
          seller: address,
          sold: false,
          cancelled: false,
          limit: LIMIT_GNOMES_REQUEST,
          offset: (page - 1) * LIMIT_GNOMES_REQUEST,
        })

        const pages = Math.ceil(data.count / LIMIT_GNOMES_REQUEST)

        setMarketplaceGnomes(prev => ({
          ...prev,
          selfGnomes: { pages, gnomes: data.results },
        }))
      } catch {
        enqueueSnackbar(t(SnackbarTranslations.FAIL_TO_RETRIEVE_MY_GNOMES), {
          variant: 'error',
        })
      }
    },
    [address, enqueueSnackbar, t],
  )

  const getSellersSales = useCallback(
    async (page: number) => {
      try {
        const data = await fetchSales({
          sold: false,
          cancelled: false,
          is_new: true,
          limit: LIMIT_GNOMES_REQUEST,
          offset: (page - 1) * LIMIT_GNOMES_REQUEST,
        })
        const pages = Math.ceil(data.count / LIMIT_GNOMES_REQUEST)

        setMarketplaceGnomes(prev => ({
          ...prev,
          sellers: { pages, gnomes: data.results },
        }))
      } catch {
        enqueueSnackbar(t(SnackbarTranslations.FAIL_TO_RETRIEVE_NEW_GNOMES), {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar, t],
  )

  const getCommunitySales = useCallback(
    async (page: number) => {
      try {
        const data = await fetchSales({
          sold: false,
          cancelled: false,
          is_new: false,
          limit: LIMIT_GNOMES_REQUEST,
          offset: (page - 1) * LIMIT_GNOMES_REQUEST,
        })
        const pages = Math.ceil(data.count / LIMIT_GNOMES_REQUEST)

        setMarketplaceGnomes(prev => ({
          ...prev,
          community: { pages, gnomes: data.results },
        }))
      } catch (err) {
        enqueueSnackbar(
          t(SnackbarTranslations.FAIL_TO_RETRIEVE_COMMUNITY_GNOMES),
          { variant: 'error' },
        )
      }
    },
    [enqueueSnackbar, t],
  )

  const getSales = useCallback(async () => {
    await getOwnSales(1)
    await getSellersSales(1)
    await getCommunitySales(1)
  }, [getOwnSales, getSellersSales, getCommunitySales])

  useEffect(() => {
    getSales()
  }, [getSales])

  const onRequestMoreOwn = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      getOwnSales(page)
    },
    [getOwnSales],
  )

  const onRequestMoreSellers = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      getSellersSales(page)
    },
    [getSellersSales],
  )

  const onRequestMoreCommunity = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      getCommunitySales(page)
    },
    [getCommunitySales],
  )

  return (
    <MarketplaceLayout data-testid="marketplace:layout">
      <Container maxWidth="xl" data-testid="marketplace:container">
        <DashboardAppBar />
        <Grid
          data-testid="marketplace:grid-title"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontFamily="Onacona" color="font.secondary" variant="h1">
            {t(CommonTranslations.MARKETPLACE)}
          </Typography>
        </Grid>
        <GnomeMarketContainer
          onRequestMore={onRequestMoreOwn}
          count={marketplaceGnomes.selfGnomes.pages}
          label={t(CommonTranslations.MY_TEAM)}
          gnomes={marketplaceGnomes.selfGnomes.gnomes}
        />
        <GnomeMarketContainer
          onRequestMore={onRequestMoreSellers}
          count={marketplaceGnomes.sellers.pages}
          label={t(CommonTranslations.NEW)}
          gnomes={marketplaceGnomes.sellers.gnomes}
        />
        <GnomeMarketContainer
          onRequestMore={onRequestMoreCommunity}
          count={marketplaceGnomes.community.pages}
          label={t(CommonTranslations.COMMUNITY)}
          gnomes={marketplaceGnomes.community.gnomes}
        />
      </Container>
    </MarketplaceLayout>
  )
})
