import React, { useCallback, useState } from 'react'
import { formatUnits } from 'ethers'
import { useTranslation } from 'react-i18next'
import { Item } from '../../../pages/Inventory/models/Item'
import { CommonTranslations, TOKEN_SYMBOL, Translations } from '../../../config'
import { useWalletContext } from '../../contexts/WalletContext'
import { CommonTitle } from '../CommonTitle/CommonTitle'
import { CommonButton } from '../CommonButton/CommonButton'
import { commons } from '../../../images'

import {
  CardActionsWrapper,
  CardContentWrapper,
  InfoTokenSymbol,
  ItemCard,
  ItemContentWrapper,
  ItemContentWrapperDouble,
  ItemDesc,
  ItemImage,
  ItemNumber,
  ItemPriceWrapper,
  ItemTitle,
  PriceToken,
  PriceUsd,
  PriceWrapper,
} from '../../../pages/Inventory/Inventory.style'


interface InventoryItemProps {
  item: Item
}

export function InventoryItem({ item }: InventoryItemProps) {
  const { t } = useTranslation([Translations.COMMON])
  const { buyItem } = useWalletContext()
  const [loading, setLoading] = useState<boolean>(false)

  const handleBuy = useCallback(async () => {
    setLoading(true)
    await buyItem(item.id, item.price)
    setLoading(false)
  }, [buyItem, item.id, item.price])

  return (
    <ItemCard data-testid="inventory-item:card">
      <CommonTitle title={item.name} />

      <CardContentWrapper data-testid="inventory-item:card-content">
        <ItemImage src={commons.mushroom} />

        <ItemContentWrapperDouble>
          <ItemTitle>{t(CommonTranslations.OPERATION)}</ItemTitle>
          <ItemDesc>{t(CommonTranslations.MUSHROOM_DESC)}</ItemDesc>
        </ItemContentWrapperDouble>

        <ItemContentWrapper>
          <ItemTitle>{t(CommonTranslations.HOLD)}</ItemTitle>
          <ItemNumber>{item.qty ?? '0'}</ItemNumber>
        </ItemContentWrapper>

        <ItemContentWrapper data-testid="inventory-item:price">
          <ItemTitle>{t(CommonTranslations.PRICE)}</ItemTitle>
          <ItemPriceWrapper>
            <PriceWrapper>
              <InfoTokenSymbol src={commons.lacSymbol} />
              <PriceToken>
                {`${formatUnits(item.price, 'ether')} ${TOKEN_SYMBOL}`}
              </PriceToken>
            </PriceWrapper>

            <PriceUsd>{item.priceUsd && `$ ${item.priceUsd ?? ''}`}</PriceUsd>
          </ItemPriceWrapper>
        </ItemContentWrapper>
      </CardContentWrapper>

      <CardActionsWrapper data-testid="inventory-item:card-actions">
        <CommonButton
          text={t(CommonTranslations.BUY)}
          clickHandler={handleBuy}
          disabled={loading}
          full
        />
      </CardActionsWrapper>
    </ItemCard>
  )
}
