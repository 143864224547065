import { Grid, styled } from '@mui/material'

export const TeamWorkerContainer = styled(Grid)({
  display: 'grid',
  gridTemplateRows: 'minmax(250px, 50%) repeat(2, minmax(50px, 25%))',
  alignItems: 'center',
  placeItems: 'center',
  height: 350,
  width: '100%',
})

export const TeamImage = styled('img')(({ theme }) => ({
  width: 200,
  height: 200,
  borderRadius: '50%',
  objectFit: 'none',
  backgroundColor: theme.palette.common.white,
}))
