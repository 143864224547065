import React from 'react'
import { Skeleton } from '@mui/material'
import { RankingImage } from './RankingCategory.style'
import { logo } from '../../../images'

export enum Rankings {
  // eslint-disable-next-line no-unused-vars
  CERAMIC = 'ceramic',
  // eslint-disable-next-line no-unused-vars
  BRONCE = 'bronce',
  // eslint-disable-next-line no-unused-vars
  SILVER = 'silver',
  // eslint-disable-next-line no-unused-vars
  GOLD = 'gold',
  // eslint-disable-next-line no-unused-vars
  PLATINUM = 'platinum',
}

export interface RankingCategoryProps {
  category?: Rankings
}

export const categories = [
  Rankings.CERAMIC,
  Rankings.BRONCE,
  Rankings.SILVER,
  Rankings.GOLD,
  Rankings.PLATINUM,
]

export function RankingCategory({ category }: RankingCategoryProps) {
  return category ? (
    <RankingImage
      data-testid={`ranking-category:${category}-image`}
      src={logo[category]}
    />
  ) : (
    <Skeleton
      data-testid="ranking-category:circular-skeleton"
      variant="circular"
      width="15rem"
      height="15rem"
    />
  )
}
