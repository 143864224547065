import { styled } from '@mui/material'
import { common } from '@mui/material/colors'
import { commons } from '../../../images'
import { GameTypography } from '../../Custom.style'

export const WoodLetterContainer = styled('div')({
  background: `url(${commons.gnomesWood})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '385px',
  height: '90px',
  color: common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1',
})

export const WoodLetterTypography = styled(GameTypography)({
  color: common.white,
  fontSize: '48px',
})
