import React from 'react'
import { GameContainer, GameIframe } from './Play.style'

export function Play() {
  return (
    <GameContainer data-testid="play:container">
      <GameIframe
        data-testid="play:iframe"
        src="game/index.htm"
        title="Blasted Gnomes"
        style={{ border: 'none' }}
        allowFullScreen
      />
    </GameContainer>
  )
}
