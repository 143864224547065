import React from 'react'
import { CommonTitleContainer } from './CommonTitle.styles'

interface CommonTitleProps {
  title: string
  subtitle?: boolean
}

export function CommonTitle({ title, subtitle }: CommonTitleProps) {
  return (
    <CommonTitleContainer subtitle={subtitle}>{title}</CommonTitleContainer>
  )
}
