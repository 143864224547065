import React from 'react'
import { ImgGnome, PreviewContainer } from './GnomePreview.style'
import { commons } from '../../../images'

export function GnomePreview() {
  return (
    <PreviewContainer data-testid="gnome-preview:preview-container">
      <ImgGnome
        src={commons.gnome}
        alt="gnome_preview"
        data-testid="gnome-preview:image-gnome"
      />
    </PreviewContainer>
  )
}
