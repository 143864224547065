import { jwtDecode } from 'jwt-decode'

const decodeToken = (token: string) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};

export const isTokenExpired = (token: string) => {
  const decoded = decodeToken(token);
  if (!decoded?.exp) return false;

  const expiryTime = decoded.exp * 1000;
  return Date.now() >= expiryTime;
};
