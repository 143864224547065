import { Grid, styled } from '@mui/material'
import { TypographyDescription } from '../../Home.style'
import { GameTypography } from '../../../../commons/Custom.style'
import { commons } from '../../../../images'

export const CompetitionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
}))

export const ContentContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(450px, 50%) minmax(250px, 50%)',
  height: '50%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  placeItems: 'center',
  minHeight: '100%',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 50%)',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0 30px',
  },
}))

export const TypographyColumnContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '10px',
})

export const TypographyCompetition = styled(GameTypography)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '50px',
  lineHeight: '60px',
  color: theme.palette.font.main,
  fontFamily: 'Gameria',
  maxWidth: '340px',
  [theme.breakpoints.down('md')]: {
    fontSize: '40px',
    lineHeight: '40px',
    textAlign: 'center',
  },
}))

export const TypographyDetail = styled(TypographyDescription)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '22px',
  maxWidth: '645px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}))

export const GridImageContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '30px',
  flexWrap: 'wrap',
  height: '100%',
  maxWidth: '750px',
}))

export const DashedImg = styled('div')({
  background: `url(${commons.dash})`,
  width: '100px',
  height: '3px',
  gridColumnStart: 1,
  gridColumnEnd: 4,
  gridRowStart: 2,
  gridRowEnd: 3,
})

export const CategoryContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '220px',
})

export const CarouselContainer = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  alignItems: 'center',
})
