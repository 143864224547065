import React from 'react'
import { Footer } from '../../commons/components/Footer'
import { TeamSection } from './components/Team/Team'
import { RoadmapSection } from './components/Roadmap/Roadmap'
import { CompetitionDescriptionSection } from './components/Competition'
import { MainSection } from './components/Main/Main'
import { GnomesCompetitionContainer, LandingPageContainer } from './Home.style'
import { MenuWrapper } from '../../commons/components/MenuWrapper'
import { PartnersSection } from './components/Partners/Partners'
import { GnomesSection } from './components/Gnomes'

export function Home() {
  return (
    <MenuWrapper>
      <LandingPageContainer data-testid="landing:page-container">
        <MainSection />

        <GnomesCompetitionContainer data-testid="landing:gnomes-competition-container">
          <CompetitionDescriptionSection />
          <GnomesSection />
        </GnomesCompetitionContainer>

        <RoadmapSection />

        <TeamSection />

        <PartnersSection />

        <Footer />
      </LandingPageContainer>
    </MenuWrapper>
  )
}
