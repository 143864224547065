import React from 'react'
import {
  ButtonContainer,
  ButtonLightLeft,
  ButtonLightRight,
  ButtonShadowContainer,
  ButtonTypography,
} from './GameButton.style'

interface ButtonProps {
  children?: string
  onClick?: () => void
}

export function GameButton({ onClick, children }: ButtonProps) {
  return (
    <ButtonContainer onClick={onClick} data-testid="game-button:container">
      <ButtonLightLeft data-testid="game-button:light-left" />
      <ButtonLightRight data-testid="game-button:light-right" />
      <ButtonTypography data-testid="game-button:typography">
        {children}
      </ButtonTypography>
      <ButtonShadowContainer data-testid="game-button:shadow-container" />
    </ButtonContainer>
  )
}
