import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CollapsableMenu } from '../CollapsableMenu/CollapsableMenu'
import { ContainerAppBar } from '../ContainerAppBar/ContainerAppBar'
import { HorizontalMenu } from '../HorizontalMenu/HorizontalMenu'
import { useAnalitycsEvents } from '../../hooks/useAnalitycsEvents'

export default function AppBar() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { trackGameplay, trackGnomes, trackRoadmap, trackWebInit } =
    useAnalitycsEvents()

  const handleInitClick = () => {
    trackWebInit()
    document
      .getElementById('main-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleGameplayClick = () => {
    trackGameplay()
    document
      .getElementById('competition-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleGnomesClick = () => {
    trackGnomes()
    document
      .getElementById('gnomes-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleRoadmapClick = () => {
    trackRoadmap()
    document
      .getElementById('roadmap-section')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <ContainerAppBar>
      {isMobile ? (
        <CollapsableMenu
          onInitClick={handleInitClick}
          onGameplayClick={handleGameplayClick}
          onGnomesClick={handleGnomesClick}
          onRoadmapClick={handleRoadmapClick}
        />
      ) : (
        <HorizontalMenu
          onInitClick={handleInitClick}
          onGameplayClick={handleGameplayClick}
          onGnomesClick={handleGnomesClick}
          onRoadmapClick={handleRoadmapClick}
        />
      )}
    </ContainerAppBar>
  )
}
