import React from 'react'
import { WoodLetterContainer } from '../Team/Team.style'
import { WoodLetter } from '../../../../commons/components/WoodLetter'
import { commons } from '../../../../images'
import { PartnersCarousel } from '../../../../commons/components/PartnersCarousel'
import {
  CloudPartners,
  PartnersCarouselContainer,
  PartnersSectionContainer,
  PartnersSectionTitleContainer,
} from './Partners.style'

export function PartnersSection() {
  return (
    <PartnersSectionContainer data-testid="partners-section:container">
      <PartnersSectionTitleContainer data-testid="partners-section:title">
        <CloudPartners
          src={commons.cloudDown}
          data-testid="partners:cloud_down_image"
          alt="Cloud down"
        />
        <WoodLetterContainer data-testid="partners-section:wood-letter-container">
          <WoodLetter label="Partners" />
        </WoodLetterContainer>
      </PartnersSectionTitleContainer>
      <PartnersCarouselContainer data-testid="partners:carousel-container">
        <PartnersCarousel />
      </PartnersCarouselContainer>
    </PartnersSectionContainer>
  )
}
