import { Container } from '@mui/material'
import React from 'react'
import { AppBarToolbar, StyledAppBar } from '../AppBar/AppBar.style'
import { AppBarContainer } from './ContainerAppBar.style'

interface ContainerAppBarProps {
  children: React.ReactNode
}
export function ContainerAppBar({ children }: ContainerAppBarProps) {
  return (
    <AppBarContainer data-testid="container-app-bar-container">
      <StyledAppBar
        data-testid="styled-app-bar"
        position="static"
        elevation={0}
      >
        <Container data-testid="container-app-bar">
          <AppBarToolbar data-testid="toolbar-app-bar">
            {children}
          </AppBarToolbar>
        </Container>
      </StyledAppBar>
    </AppBarContainer>
  )
}
