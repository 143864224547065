import {
  styled,
  AppBar,
  Typography,
  Button,
  Box,
  Toolbar,
  Menu,
  IconButton,
} from '@mui/material'

export const StyledAppBar = styled(AppBar)({
  padding: 0,
})

export const AppBarTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: '2.1rem',
  fontWeight: 'bold',
  border: '1px solid #000000',
  padding: '1rem 4rem',
  borderRadius: '5px',
  [theme.breakpoints.down('md')]: {
    flexGrow: 1,
    fontSize: '1.3rem',
    border: 'none',
    padding: '0 1rem',
    borderRadius: '0',
  },
}))

export const CustomButtonLanguage = styled(Button)(({ theme }) => ({
  padding: '0.5rem 2.5rem',
  marginRight: -80,
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 0,
  },
}))

export const CustomIconButton = styled(IconButton)({
  border: '1px solid #000000',
  padding: '1rem 2rem',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
})

export const AppBarMenuWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  height: 90,
  [theme.breakpoints.down('md')]: {
    display: 'none',
    gap: 'none',
  },
}))

export const AppBarMenuButton = styled(Button)({
  my: 2,
  display: 'block',
  backgroundColor: 'transparent',
  color: '#ffffff',
  border: `none`,
  padding: '5px 10px',
  fontSize: '2rem',
  ':hover': {
    backgroundColor: 'transparent',
    border: `none`,
  },
  '&.active': {
    border: `none`,
    fontWeight: 'bolder',
  },
})

export const AppBarToolbar = styled(Toolbar)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.up('md')]: {
    marginTop: 75,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}))

export const CollapsedMenuWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}))

export const CollapsedMenu = styled(Menu)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

export const BlastedAppBarIcon = styled('img')({
  width: '230px',
  height: '170px',
  margin: '0 -60px 0 -40px',
  zIndex: 3,
  userSelect: 'none',
  pointerEvents: 'none',
})
