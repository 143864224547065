import { css, styled } from '@mui/material'

interface CommonTitleProps {
  subtitle?: boolean
}

export const CommonTitleContainer = styled('h3')<CommonTitleProps>(
  ({ theme, subtitle }) => css`
    width: fit-content;
    color: ${theme.palette.font.neutral60};
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.32px;
    text-transform: capitalize;
    text-align: left;

    ${subtitle &&
    css`
      font-size: 1.8rem;
      line-height: 40px;
      letter-spacing: -0.26px;
    `}
  `,
)
