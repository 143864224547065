import React from 'react'
import {
  CarouselContainer,
  Cloud,
  CustomRoadmapTitleContainer,
  RoadmapSectionContainer,
} from './Roadmap.style'
import { commons } from '../../../../images'
import { RoadmapCarousel } from '../../../../commons/components/RoadmapCarousel'
import { WoodLetter } from '../../../../commons/components/WoodLetter'

export function RoadmapSection() {
  return (
    <RoadmapSectionContainer
      id="roadmap-section"
      data-testid="roadmap:section-container"
    >
      <CustomRoadmapTitleContainer data-testid="roadmap:custom-container-title">
        <Cloud
          src={commons.cloudUp}
          data-testid="roadmap:cloud_up_image"
          alt="cloud_up"
        />
        <WoodLetter label="Roadmap" />
      </CustomRoadmapTitleContainer>
      <CarouselContainer data-testid="roadmap:carousel-container">
        <RoadmapCarousel />
      </CarouselContainer>
    </RoadmapSectionContainer>
  )
}
