import { css, styled } from '@mui/material'

export const Center = styled('div')(
  () => css`
    text-align: center;
    margin: 0 1px;
    letter-spacing: 1px;
  `,
)

export const Right = styled('div')(
  () => css`
    text-align: left;
  `,
)

export const Left = styled('div')(
  () => css`
    text-align: right;
  `,
)

export const AddressContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: baseline;
    justify-content: center;
    white-space: nowrap;

    * {
      color: ${theme.palette.font.neutral60};
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  `,
)
