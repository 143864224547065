export const isTxRejected = (err: Error | undefined) => {
  const REJECTED_CODE = 4001
  const REJECTED_CODE_MESSAGE = 'ACTION_REJECTED'
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const {cause, code} = err as any
    return cause?.code === REJECTED_CODE || code === REJECTED_CODE_MESSAGE
  } catch {
    return false
  }
}
