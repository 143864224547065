import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TOKEN_SYMBOL } from '../../config'
import { RoutesEnum } from '../../routes/constants'
import { Gnome, GnomeType } from '../../commons/types'
import { CommonTranslations, Translations } from '../../config/i18n/i18n'

import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
import { CommonTitle } from '../../commons/components/CommonTitle/CommonTitle'
import { GnomeCardNew } from '../../commons/components/GnomeCardNew'
import { commons } from '../../images'
import { SelectSort } from '../../commons/components/SelectSort/SelectSort'
import { CommonButton } from '../../commons/components/CommonButton/CommonButton'
import { GnomeDetails } from '../GnomeDetails'
import { ModalCommon } from '../../commons/components/ModalCommon/ModalCommon'
import { SellGnomeModal } from './Components/SellGnomeModal/SellGnomeModal'

import {
  TeamContainer,
  ContentContainer,
  GnomesList,
  GnomeSortWrapper,
  InventoryWrapper,
  InventoryPanel,
  PanelTitle,
  PanelBalance,
  TokenSymbol,
  TokenLogo,
  PanelInventory,
} from './GnomesTeam.style'

export function GnomesTeam() {
  const { t } = useTranslation([Translations.COMMON])

  const gnomeExample: Gnome[] = [
    {
      id: 1234,
      owner: 'bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh',
      can_level_up: true,
      metadata: {
        image: `${commons.gnomePreview}`,
        gnome_type: GnomeType.Rare,
        price_lac: '1.34',
        attributes: [
          { value: '433', trait_type: 'Level' },
          { value: '22', trait_type: 'Xp' },
        ],
      },
    },
    {
      id: 3456,
      owner: 'bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh',
      can_level_up: false,
      metadata: {
        image: `${commons.gnomePreview}`,
        gnome_type: GnomeType.Epic,
        price_lac: '1.34',
        attributes: [
          { value: '1', trait_type: 'Level' },
          { value: '444', trait_type: 'Xp' },
        ],
      },
    },
    {
      id: 555,
      owner: 'bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh',
      can_level_up: false,
      metadata: {
        image: `${commons.gnomePreview}`,
        gnome_type: GnomeType.Infrequent,
        price_lac: '1.34',
        attributes: [
          { value: '4', trait_type: 'Level' },
          { value: '4424', trait_type: 'Xp' },
        ],
      },
    },
    {
      id: 5535,
      owner: 'bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh',
      can_level_up: false,
      metadata: {
        image: `${commons.gnomePreview}`,
        gnome_type: GnomeType.Rare,
        price_lac: '1.34',
        attributes: [
          { value: '43', trait_type: 'Level' },
          { value: '33333', trait_type: 'Xp' },
        ],
      },
    },
  ]

  /* Handle show gnomes */
  const [selectedGnome, setSelectedGnome] = useState<Gnome | null>(null)

  const selectGnome = (gnome: Gnome) => setSelectedGnome(gnome)
  const removeGnome = () => setSelectedGnome(null)

  /* Handle sell modal */
  const [showSellModal, setShowSellModal] = useState<boolean>(false)
  const handleShowModal = () => setShowSellModal(true)
  const handleCloseModal = () => setShowSellModal(false)
  // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
  const onConfirm = (gnomeId?: number | null) => {
    setShowSellModal(false)
  }

  return (
    <TeamContainer>
      <DashboardAppBar />

      <ContentContainer>
        {!selectedGnome && (
          <>
            <CommonTitle title={`${t(CommonTranslations.GNOMES_TEAM_TITLE)}`} />

            <InventoryWrapper>
              <InventoryPanel>
                <PanelTitle>
                  {t(CommonTranslations.PANEL_INVENTORY_BALANCE)}
                </PanelTitle>

                <PanelBalance>
                  <TokenLogo src={commons.lacSymbol} />
                  <TokenSymbol>{`${500} ${TOKEN_SYMBOL}`}</TokenSymbol>
                </PanelBalance>
              </InventoryPanel>

              <InventoryPanel>
                <PanelTitle>
                  {t(CommonTranslations.PANEL_INVENTORY_MUSHROOMS)}
                </PanelTitle>

                <PanelInventory>
                  <TokenSymbol>{1}</TokenSymbol>
                  <CommonButton
                    text={t(CommonTranslations.GO_TO_INVENTORY)}
                    small
                    to={RoutesEnum.INVENTORY}
                  />
                </PanelInventory>
              </InventoryPanel>
            </InventoryWrapper>

            <GnomeSortWrapper>
              <CommonTitle title={`${gnomeExample?.length} gnomos`} subtitle />
              <SelectSort defaultOptions />
            </GnomeSortWrapper>

            <GnomesList>
              {gnomeExample?.map(gnome => (
                <GnomeCardNew
                  key={gnome.id}
                  gnome={gnome}
                  showGnome={() => selectGnome(gnome)}
                  gnomeTeam
                />
              ))}
            </GnomesList>
          </>
        )}

        {selectedGnome && (
          <GnomeDetails
            gnome={selectedGnome}
            backAction={removeGnome}
            mainButtonAction={handleShowModal}
            tsx="sell"
          />
        )}
      </ContentContainer>

      {/* Sell Gnome modal */}
      <ModalCommon isShown={showSellModal} closeModal={handleCloseModal}>
        <SellGnomeModal
          gnome={selectedGnome}
          onConfirm={() => onConfirm(selectedGnome && selectedGnome.id)}
        />
      </ModalCommon>
    </TeamContainer>
  )
}
