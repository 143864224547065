import { Gnome } from '../types'

export interface GnomeAttribute {
  gender: string
  hat: string
  hat_type: string
  nose: string
  nose_type: string
  hair: string
  hair_type: string
  clothes: string
  clothes_type: string
  tier: string
  xp: number
  level: number
}

export const getAttributes = (gnome: Gnome): GnomeAttribute =>
  gnome.metadata.attributes.reduce(
    (result: GnomeAttribute, elem) => ({
      ...result,
      [elem.trait_type]: elem.value,
    }),
    {} as GnomeAttribute,
  )

export const hashShortener = (hash: string | undefined) =>
  hash ? `${hash.slice(0, 4)}...${hash.slice(-2)}` : ''
