import { styled } from '@mui/material'

export const PreviewContainer = styled('div')(() => ({
  display: 'flex',
  maxHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '350px',
  width: '100%',
}))

export const ImgGnome = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  userSelect: 'none',
  pointerEvents: 'none',
  width: '80%',
  [theme.breakpoints.down('md')]: {
    height: '400px',
  },
}))
